import { FC, useContext, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { object } from 'yup';
import { ActionIcon, Button, Image, Skeleton } from '@mantine/core';
import { yupResolver } from '@mantine/form';

import { api } from '@chess-class/sandbox';
import { NotFoundResult } from '@chess-class/sandbox/components';
import { EditIcon } from '@chess-class/sandbox/icons';

import InputSkeleton from '~/components/atoms/skeletons/InputSkeleton';
import { PageMeta } from '~/context/PageMetaContext';
import { StudentContext } from '~/context/StudentContext';
import { UserFormProvider, useUserForm } from '~/context/UserProfileFormContext';

import ChangePasswordForm from '../components/molecules/ChangePasswordForm';
import ProfileForm from '../components/molecules/ProfileForm';
import ProfileInfo from '../components/molecules/ProfileInfo';
import SchoolCard from '../components/molecules/SchoolCard';

export const ProfilePage: FC = () => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const meQuery = api.user.studentAuth.useStudentMe();
  const student = meQuery.data;
  const { school } = useContext(StudentContext);

  const profileUpdate = api.user.students.useMutateProfileUpdate();
  const changePassword = api.user.students.useStudentProfilePasswordUpdate();

  const groupQuery = api.schools.groups.useGroup({ id: student?.groupId || '' });
  const group = groupQuery?.data?.data;

  const [isReset, setIsReset] = useState(false);

  const fullProfileValidation = useMemo(() => {
    return isReset
      ? object()
          .concat(api.user.students.helpers.profileUpdateValidation)
          .concat(api.user.students.helpers.studentProfilePasswordUpdateValidation)
      : api.user.students.helpers.profileUpdateValidation;
  }, [isReset]);

  const form = useUserForm({
    initialValues: {
      ...api.user.students.helpers.studentProfileUpdateInitial,
      ...api.user.students.helpers.studentChangePasswordInitial,
    },
    validate: yupResolver(fullProfileValidation),
  });

  useEffect(() => {
    if (student) {
      const {
        birthDate,
        email,
        firstName,
        gender,
        groupName,
        id,
        iin,
        lastName,
        middleName,
        phone,
      } = student;

      form.setValues({
        birthDate: birthDate ? (new Date(birthDate) as unknown as string) : null,
        email,
        firstName,
        gender,
        groupName,
        id,
        iin,
        lastName,
        middleName,
        phone,
      });
    }
  }, [student]);

  return (
    <>
      <PageMeta selectedMenuKeys={['profile']} title={t('profile')} />
      {meQuery.isLoading ? (
        <div className="grid grid-cols-2 gap-y-6 gap-x-4">
          <Skeleton className="h-20 w-20 rounded-xl col-span-full" />
          <InputSkeleton label />
          <InputSkeleton label />
          <InputSkeleton label />
          <InputSkeleton label />
          <InputSkeleton label />
          <InputSkeleton label />

          <Skeleton className="h-20 w-20 rounded-xl col-span-full" />
          <InputSkeleton label />
          <InputSkeleton label />
        </div>
      ) : !student ? (
        <NotFoundResult title={t('messages.errorProfileNotFound')} />
      ) : (
        <>
          <UserFormProvider form={form}>
            <form
              onSubmit={form.onSubmit(async (values) => {
                await profileUpdate
                  .mutateAsync({
                    birthDate: values.birthDate,
                    email: values.email,
                    firstName: values.firstName,
                    gender: values.gender,
                    groupName: values.groupName,
                    id: values.id,
                    iin: values.iin,
                    lastName: values.lastName,
                    middleName: values.middleName,
                    phone: values.phone,
                  })
                  .then(() =>
                    ReactGA.event({
                      action: 'edit_profile',
                      category: 'edit_profile',
                      label: 'edit_profile',
                    }),
                  );

                if (isReset) {
                  changePassword
                    .mutateAsync({
                      confirmPassword: values.confirmPassword,
                      newPassword: values.newPassword,
                    })
                    .catch((error) => {
                      if (
                        error.response?.data.status == 400 &&
                        error.response.data.message == 'PASSWORD_MUST_BE_DIFFERENT'
                      ) {
                        form.setFieldError('email', t('profilePage.passwordRepeatError'));
                        setEditMode(true);
                      }
                    });
                }
                setEditMode(false);
                setIsReset(false);
              })}
            >
              <div className="bg-white py-8 px-5 rounded-xl border border-[#E4E6ED] ">
                <div className="flex justify-between">
                  <div className="flex flex-row justify-start items-center">
                    <Image
                      alt="Profile image"
                      className="mr-6 ml-0 object-contain"
                      maw={120}
                      mx="auto"
                      radius="xl"
                      src={`/images/illustrations/${student?.gender?.toLowerCase()}.png`}
                    />
                    <div className="flex flex-col">
                      <h3 className="text-[#11122C] md:text-2xl font-bold text-base">{`${student.firstName} ${student.lastName} ${student.middleName}`}</h3>
                      <div className="text-[#7D829A] . flex flex-row">
                        <span>
                          {school?.educationFormat == 'GENERAL' ? t('class') : t('group')}:{' '}
                          {student?.groupName}
                        </span>
                        <span className="ml-4">
                          {t('school')}: {group?.school?.name}
                        </span>
                      </div>
                    </div>
                  </div>
                  {!editMode && (
                    <ActionIcon className="mr-4" onClick={() => setEditMode(true)}>
                      <EditIcon color="#667080" />
                    </ActionIcon>
                  )}
                </div>
                {editMode && (
                  <p className="text-[#11122C] font-bold my-6">{t('profilePage.personalInfo')}</p>
                )}
                {editMode ? <ProfileForm /> : <ProfileInfo student={student} />}
              </div>
              {group?.school && (
                <div className="bg-white py-8 px-5 rounded-xl border !border-1 border-[#E4E6ED] md:my-8 my-4">
                  <SchoolCard group={group} school={group.school} />
                </div>
              )}
              {editMode && (
                <>
                  <div className="bg-white py-8 px-5 rounded-xl border !border-1 border-[#E4E6ED]">
                    <ChangePasswordForm isReset={isReset} setIsReset={setIsReset} />
                  </div>

                  <div className="col-span-full flex gap-4 my-8 md:flex-row flex-col">
                    <Button
                      className="md:w-44 w-full text-[#4F5672] border-[#4F5672]"
                      onClick={() => {
                        setIsReset(false);
                        setEditMode(false);
                      }}
                      variant="outline"
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      className="md:w-44 w-full"
                      disabled={!form.isDirty()}
                      type="submit"
                      variant="primary"
                    >
                      {t('save')}
                    </Button>
                  </div>
                </>
              )}
            </form>
          </UserFormProvider>
        </>
      )}
    </>
  );
};
