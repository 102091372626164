import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Accordion, Skeleton, Tabs, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { modals } from '@mantine/modals';

import { api, IEnumReviewContentType, ILocalization } from '@chess-class/sandbox';
import { NotFoundResult, PlyrPlayer } from '@chess-class/sandbox/components';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { GuidelineChapterExercisesIcon } from '@chess-class/sandbox/icons';

import { ReviewContentModal } from '~/components/organisms/ReviewContentModal';
import { PageMeta } from '~/context/PageMetaContext';
import { StudentContext } from '~/context/StudentContext';

import ExerciseCardTable from '~mLessons/components/molecules/ExerciseCardTable';
import ExerciseDetailsModal from '~mLessons/components/molecules/ExerciseDetailsModal';
import TestDetailsModal from '~mLessons/components/molecules/TestDetailsModal';
import {
  ILessonPageActions,
  lessonPageActions,
} from '~mLessons/consts/pageActions/ILessonPageActions';

export default function LessonPage() {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { actioned, clearParams, setActioned, setPartialActioned } = useUrlFilteredActioned<
    undefined,
    ILessonPageActions
  >(undefined, lessonPageActions);
  const matches = useMediaQuery('(min-width: 768px)');
  const [isOpenRateContentModal, setIsOpenRateContentModal] = useState(false);
  const guideChapterQuery = api.guideline.guidelineChapters.useGuidelineChapter({ id });
  const guideChapter = guideChapterQuery.data?.data;
  const { school, student } = useContext(StudentContext);
  const videos = useMemo(() => {
    return [...(guideChapter?.lessons || []), ...(guideChapter?.cartoons || [])];
  }, [guideChapter]);

  const reviewsQuery = api.reviews.reviews.useQueryStudentCoachReview({
    contentId: id ?? '',
    contentType: 'CHAPTER',
    educationFormat: school?.educationFormat ?? 'PRIVATE',
    userId: student?.id ?? '',
  });

  const reviews = reviewsQuery.data?.data;

  useEffect(() => {
    if (reviews && !reviews.find((r) => r.contentId == id)) {
      const timer = setTimeout(() => {
        setIsOpenRateContentModal(true);
      }, 200); // 2 minutes in milliseconds
      return () => clearTimeout(timer);
    }
  }, []);

  const exerciseIndex =
    guideChapter?.classWorkExercises.findIndex((ex) => ex.id == actioned.actionId) ?? 0;

  return (
    <div className="flex flex-col gap-3 md:gap-8">
      <PageMeta
        goBack={{ i18Title: 'back', url: '/lessons' }}
        selectedMenuKeys={['lessons']}
        title={`${t('chapter')} - ${guideChapter?.translations?.[language]?.title || id}`}
      />
      {guideChapterQuery.isLoading ? (
        <div className="flex flex-col gap-8">
          <Skeleton className="w-full h-40 rounded-xl" />
          <Skeleton className="w-full h-24 rounded-xl" />
        </div>
      ) : !guideChapter ? (
        <NotFoundResult />
      ) : (
        <>
          <div className="flex gap-8 justify-between items-start md:p-8 px-4 py-6 bg-white rounded-xl border border-1 border-[#E4E6ED] md:border-none">
            <div className="flex gap-8">
              <div className="hidden md:flex justify-center items-start relative">
                <img
                  alt={t('chapter')}
                  className="w-32 h-24 object-cover rounded-xl"
                  src="/images/illustrations/guideline-chapter.png"
                />
              </div>
              <div className="flex flex-col gap-3">
                <span className="font-bold text-lg text-primary-900">
                  {t('chapter')}{' '}
                  {school?.educationFormat == 'GENERAL'
                    ? guideChapter.priority
                    : guideChapter.numId}
                  : {guideChapter.translations?.[language]?.title}
                </span>
                <span className="text-[#B9B9B9] flex items-center gap-1">
                  <GuidelineChapterExercisesIcon />
                  {`${guideChapter.classWorkExercises?.length || 0} ${t('problem', {
                    count: guideChapter.classWorkExercises?.length || 0,
                  }).toLowerCase()}`}
                </span>
                <span className="text-gray-500">
                  {guideChapter.translations?.[language]?.description}
                </span>
              </div>
            </div>
          </div>

          {matches && !!videos.length && (
            <div className="md:grid grid-cols-2 gap-7 hidden">
              {videos.map((video) => (
                <div
                  className="w-full flex flex-col gap-6 rounded-lg bg-white mx-auto"
                  key={video.id}
                >
                  <PlyrPlayer
                    className="w-full aspect-video rounded-t-lg overflow-hidden"
                    localization={language as ILocalization}
                    video={video}
                  />
                  <div className="flex flex-col px-4 pb-6">
                    <h3 className="text-base font-bold">
                      {video.translations?.[language]?.fullTitle ??
                        video.translations?.[language]?.title}
                    </h3>
                    {!!video.test && (
                      <>
                        <span className="text-[#667080] font-medium">
                          {t('author')}:{' '}
                          {video.translations?.[language]?.videoFile?.author?.name || '-'}
                        </span>
                        <span className="text-gray-600 font-semibold">
                          {video.translations?.[language]?.videoFile?.author?.rank || 'GM'}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}

          <Tabs
            classNames={{ root: 'flex-col gap-6 hidden md:flex', tabsList: 'flex gap-4' }}
            defaultValue={
              guideChapter?.additionalMaterialImages?.length ? 'materials' : 'exercises'
            }
          >
            <Tabs.List>
              {!!guideChapter?.additionalMaterialImages?.length && (
                <Tabs.Tab value="materials">{t('materials')}</Tabs.Tab>
              )}
              <Tabs.Tab value="exercises">{t('homeworksPage.tasks')}</Tabs.Tab>
            </Tabs.List>

            {!!guideChapter?.additionalMaterialImages?.length && (
              <Tabs.Panel value="materials">
                <div className="flex flex-col md:gap-8">
                  {!guideChapter.additionalMaterialImages?.length ? (
                    <NotFoundResult subTitle={t('notFoundResult.noMaterials')} title="" />
                  ) : (
                    guideChapter.additionalMaterialImages?.map((imgUrl, index) => (
                      <div
                        className="bg-white p-8 flex gap-8 border rounded-lg items-center"
                        key={`chapter-additional-${index}`}
                      >
                        {imgUrl && (
                          <img
                            className="h-52 w-auto max-w-[208px] object-contain rounded-lg cursor-pointer"
                            onClick={() => {
                              modals.open({
                                children: (
                                  <img
                                    className="h-[66vh] max-w-full w-auto object-contain rounded-lg m-auto"
                                    src={imgUrl || ''}
                                  />
                                ),
                                classNames: { body: 'flex' },
                                size: '75vh',
                              });
                            }}
                            src={imgUrl || ''}
                          />
                        )}
                        <div className="flex flex-col gap-3">
                          <span className="text-lg font-bold">
                            {guideChapter.translations[language]?.additionalMaterialTitles?.[
                              index
                            ] || '-'}
                          </span>
                          <span className="text-gray-500">
                            {guideChapter.translations[language]?.additionalMaterialDescriptions?.[
                              index
                            ] || '-'}
                          </span>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </Tabs.Panel>
            )}
            <Tabs.Panel value="exercises">
              {!guideChapter.classWorkExercises?.length ? (
                <NotFoundResult subTitle={t('notFoundResult.noChapterExercises')} title="" />
              ) : (
                <ExerciseCardTable
                  exercises={guideChapter.classWorkExercises}
                  setActioned={setActioned}
                />
              )}
            </Tabs.Panel>
          </Tabs>

          {/* FIXME: make a separate component */}
          <Accordion
            chevronPosition="right"
            className="lesson-accordion block md:hidden"
            variant="contained"
          >
            <p className="text-base px-4 py-5 mb-0 font-bold">{t('content')}</p>
            <Accordion.Item
              className="mb-0 lesson-accordion-item"
              key="video-files"
              value="video-files"
            >
              <Accordion.Control className="p-0 mb-0">
                <Text>{t('videofiles')}</Text>
              </Accordion.Control>
              <Accordion.Panel>
                {!matches && !!videos.length && (
                  <div className="flex flex-col gap-7 mt-4">
                    {videos.map((video) => (
                      <div
                        className="w-full flex flex-col gap-6 rounded-lg bg-white mx-auto"
                        key={video.id}
                      >
                        <PlyrPlayer
                          className="w-full aspect-video rounded-t-lg overflow-hidden"
                          localization={language as ILocalization}
                          video={video}
                        />
                        <div className="flex flex-col px-4 pb-6">
                          <h3 className="text-base font-bold">
                            {video.translations?.[language]?.fullTitle ??
                              video.translations?.[language]?.title}
                          </h3>
                          {!!video.test && (
                            <>
                              <span className="text-[#667080] font-medium">
                                {t('author')}:{' '}
                                {video.translations?.[language]?.videoFile?.author?.name || '-'}
                              </span>
                              <span className="text-gray-600 font-semibold">
                                {video.translations?.[language]?.videoFile?.author?.rank || 'GM'}
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </Accordion.Panel>
            </Accordion.Item>

            {!!guideChapter?.additionalMaterialImages?.length && (
              <Accordion.Item
                className="mb-0 lesson-accordion-item"
                key="add-materials"
                value="add-materials"
              >
                <Accordion.Control className="p-0 mb-0">
                  <Text>{t('materials')}</Text>
                </Accordion.Control>
                <Accordion.Panel>
                  {!guideChapter.additionalMaterialImages?.length ? (
                    <NotFoundResult subTitle={t('notFoundResult.noMaterials')} title="" />
                  ) : (
                    guideChapter.additionalMaterialImages?.map((imgUrl, index) => (
                      <div
                        className="bg-white p-8 flex gap-8 border rounded-lg items-center mt-2"
                        key={`chapter-additional-${index}`}
                      >
                        {imgUrl && (
                          <img
                            className="h-52 w-auto max-w-[208px] object-contain rounded-lg cursor-pointer"
                            onClick={() => {
                              modals.open({
                                children: (
                                  <img
                                    className="h-[66vh] max-w-full w-auto object-contain rounded-lg m-auto"
                                    src={imgUrl || ''}
                                  />
                                ),
                                classNames: { body: 'flex' },
                                size: '75vh',
                              });
                            }}
                            src={imgUrl || ''}
                          />
                        )}
                        <div className="flex flex-col gap-3">
                          <span className="text-lg font-bold">
                            {guideChapter.translations[language]?.additionalMaterialTitles?.[
                              index
                            ] || '-'}
                          </span>
                          <span className="text-gray-500">
                            {guideChapter.translations[language]?.additionalMaterialDescriptions?.[
                              index
                            ] || '-'}
                          </span>
                        </div>
                      </div>
                    ))
                  )}
                </Accordion.Panel>
              </Accordion.Item>
            )}

            <Accordion.Item
              className="mb-0 lesson-accordion-item"
              key="exercises"
              value="exercises"
            >
              <Accordion.Control className="p-0 mb-0">
                <Text>{t('homeworksPage.tasks')}</Text>
              </Accordion.Control>
              <Accordion.Panel>
                {!guideChapter.classWorkExercises?.length ? (
                  <NotFoundResult subTitle={t('notFoundResult.noChapterExercises')} title="" />
                ) : (
                  <ExerciseCardTable
                    exercises={guideChapter.classWorkExercises}
                    setActioned={setActioned}
                  />
                )}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <TestDetailsModal
            exercise={guideChapter.classWorkExercises.find((ex) => ex.id === actioned.actionId)}
            isBackButtonDisabled={exerciseIndex == 0}
            isFetching={guideChapterQuery.isFetching}
            isNextButtonDisabled={exerciseIndex >= guideChapter.classWorkExercises.length - 1}
            isOpen={actioned.action === 'view' && actioned.actionType === 'test'}
            onBackButtonClick={() =>
              setPartialActioned({
                action: 'view',
                actionId: `${guideChapter.classWorkExercises[exerciseIndex - 1].id}`,
                actionType: 'test',
              })
            }
            onCancel={clearParams}
            onNextButtonClick={() => {
              setPartialActioned({
                action: 'view',
                actionId: `${guideChapter.classWorkExercises[exerciseIndex + 1].id}`,
                actionType: 'test',
              });
            }}
          />
          <ExerciseDetailsModal
            exercise={guideChapter.classWorkExercises.find((ex) => ex.id == actioned.actionId)}
            isBackButtonDisabled={exerciseIndex == 0}
            isNextButtonDisabled={exerciseIndex >= guideChapter.classWorkExercises.length - 1}
            isOpen={actioned.action === 'view' && actioned.actionType === 'exercise'}
            onBackButtonClick={() =>
              setPartialActioned({
                action: 'view',
                actionId: `${guideChapter.classWorkExercises[exerciseIndex - 1].id}`,
                actionType: 'exercise',
              })
            }
            onCancel={clearParams}
            onNextButtonClick={() => {
              setPartialActioned({
                action: 'view',
                actionId: `${guideChapter.classWorkExercises[exerciseIndex + 1].id}`,
                actionType: 'exercise',
              });
            }}
          />
          <ReviewContentModal
            contentId={id ?? ''}
            contentType={IEnumReviewContentType.CHAPTER}
            isOpen={isOpenRateContentModal}
            onClose={() => setIsOpenRateContentModal(false)}
            title={t('review.reviewLesson')}
          />
        </>
      )}
    </div>
  );
}
