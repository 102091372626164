import { IExercise } from '@chess-class/sandbox';
import { ChessboardExercise } from '@chess-class/sandbox/components';

export default function StudioExerciseProblem({
  disabled,
  exercise,
  onAnswer,
  solved,
}: {
  disabled: boolean;
  exercise: IExercise;
  onAnswer: (passed: boolean) => void;
  solved: boolean;
}) {
  return (
    <ChessboardExercise
      exercise={exercise}
      isView={disabled}
      onMove={(type) => {
        if (type == 'fail' || type == 'win' || type == 'good') {
          if (type == 'fail') {
            onAnswer(false);
          }
          if (type == 'win') {
            onAnswer(true);
          }
        }
      }}
      sidebar={{
        moveActions: true,
        solutionBox: { initialShowMoves: solved, show: true },
        yourMoveBox: { show: true },
      }}
    />
  );
}
