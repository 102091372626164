import { createContext, FC, ReactNode, useContext } from 'react';

import { api, ISchool, IStudent } from '@chess-class/sandbox';

type StudentContextValues = {
  isFirstClass: boolean;
  /**
   * Set back button of the page
   */
  school?: ISchool;
  schoolLoading?: boolean;
  student?: IStudent;
  studentLoading?: boolean;
};

export const StudentContext = createContext<StudentContextValues>({
  isFirstClass: false,
  school: undefined,
  schoolLoading: true,
  student: undefined,
  studentLoading: true,
});

export const StudentContextProvider: FC<{ children?: ReactNode }> = (props) => {
  const { data: student, isLoading: studentLoading } = api.user.studentAuth.useStudentMe();
  const schoolQuery = api.schools.schools.useSchool({ id: student?.schoolId });
  const school = schoolQuery.data?.data;

  return (
    <StudentContext.Provider
      value={{
        isFirstClass: !!student?.groupName.startsWith('1'),
        school,
        schoolLoading: schoolQuery.isLoading,
        student,
        studentLoading,
      }}
    >
      {props.children}
    </StudentContext.Provider>
  );
};

export const useStudentContext = () => {
  const studentContext = useContext(StudentContext);

  if (typeof studentContext === 'undefined') {
    throw new Error('useStudentContext must be used within a StudentContextProvider');
  }

  return studentContext;
};
