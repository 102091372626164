import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';

import { IEnumLocalization } from '@chess-class/sandbox';
import { ArrowBackIcon } from '@chess-class/sandbox/icons';

import { LanguageDropdown } from '~/components/molecules/LanguageDropdown';
import AuthRoute from '~/components/molecules/routes/AuthRoute';

const AuthLayout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const isLoginPage = useMemo(() => {
    return location.pathname != '/login';
  }, [location]);

  return (
    <AuthRoute auth={false}>
      <div className="w-full h-full grid md:grid-cols-2">
        <div className="flex flex-col h-full gap-8 p-8 md:gap-16 md:p-16 overflow-auto">
          <div className="w-full flex gap-6">
            {isLoginPage && (
              <Button
                className="border-[#EAECEE] text-[#0C1116] font-thin"
                leftIcon={<ArrowBackIcon className="w-4 h-4 text-gray-400" />}
                onClick={() => navigate('/login')}
                variant="outline"
              >
                {t('goBack')}
              </Button>
            )}
            <div className="flex justify-end flex-grow">
              <LanguageDropdown languages={Object.keys(IEnumLocalization)} />
            </div>
          </div>
          <div className="bg-white flex flex-col w-full flex-grow items-center justify-center">
            <Outlet />
          </div>
        </div>

        <div className="md:flex hidden bg-[#C8CEFF] w-full h-full flex-col text-center items-center justify-center p-8 sm:p-16 md:p-24 lg:p-32 gap-8 overflow-hidden">
          <img
            alt="class"
            className="max-w-full max-h-full w-80 aspect-square object-contain flex-grow"
            src="/images/illustrations/login.png"
          />
          <h1 className="text-primary-103 text-lg md:text-xl lg:text-2xl font-bold max-w-full">
            <span dangerouslySetInnerHTML={{ __html: t('auth.welcomeToChess') }}></span>
            <span className="text-primary" />
          </h1>
        </div>
      </div>
    </AuthRoute>
  );
};

export default AuthLayout;
