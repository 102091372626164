import { FunctionComponent, SVGProps } from 'react';
import i18n from 'i18next';

import {
  AssignmentIcon,
  IllustrationHomeworksIcon,
  IllustrationLessonsBoardIcon,
  IllustrationProfileIcon,
} from '@chess-class/sandbox/icons';

export interface SidebarInterface {
  children?: SidebarInterface[];
  icon?: FunctionComponent<Omit<SVGProps<SVGSVGElement>, 'ref'>>;
  key: string;
  link: string;
  title?: string;
}

export const sidebars = ({ isFirstClass }: { isFirstClass?: boolean }): SidebarInterface[] => {
  return [
    {
      icon: IllustrationLessonsBoardIcon,
      key: 'lessons',
      link: 'lessons',
      title: i18n.t('lessons'),
    },
    {
      icon: IllustrationHomeworksIcon,
      key: 'homework',
      link: 'homework',
      title: isFirstClass ? i18n.t('independentWork') : i18n.t('homeworks'),
    },
    {
      icon: AssignmentIcon,
      key: 'classQuizzes',
      link: 'classQuizzes',
      title: i18n.t('assignment'),
    },
    {
      icon: IllustrationProfileIcon,
      key: 'profile',
      link: 'profile',
      title: i18n.t('profile'),
    },
  ];
};
