import { Navigate } from 'react-router-dom';
import { Skeleton } from '@mantine/core';

import { api } from '@chess-class/sandbox';

import { sidebars } from '~/consts/sidebars';

const IndexRoute = () => {
  const me = api.user.studentAuth.useStudentMe();

  const navbar = sidebars({}).find(() => true);

  if (navbar?.link) return <Navigate to={navbar.link} />;

  if (me.isFetching) {
    return (
      <div className="bg-white p-10 md:p-15 mt-5 rounded-2xl">
        <Skeleton className="w-full rounded-lg h-24" />
        <Skeleton className="w-full rounded-lg h-24" />
        <Skeleton className="w-full rounded-lg h-24" />
      </div>
    );
  }

  return <div />;
};

export default IndexRoute;
