import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { api, IStudentResetInvokeRequest } from '@chess-class/sandbox';

const ResetEmailForm: FC = () => {
  const resetEmail = api.user.studentAuth.useStudentResetInvoke();
  const { t } = useTranslation();

  const form = useForm<IStudentResetInvokeRequest>({
    initialValues: api.user.studentAuth.helpers.studentResetInvokeInitial,
    validate: yupResolver(api.user.studentAuth.helpers.studentResetInvokeValidation),
  });

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        resetEmail.mutateAsync(values).catch((e) => {
          if (e.response?.status == 401) form.setFieldError('email', t('auth.incorrectLogin'));
        });
      })}
    >
      <div className="text-center">
        <h2 className="text-center text-3xl sm:text-4xl text-gray-900 truncate font-bold">
          {t('auth.resetPassword')}
        </h2>

        <p className=" text-[#4F5B67] mt-6 mb-8">{t('auth.resetPasswordMessage')}</p>
      </div>

      <TextInput
        label={t('auth.email')}
        name="email"
        placeholder={t('auth.enterEmail')}
        required
        {...form.getInputProps('email')}
      />

      <div className="flex flex-col mt-8">
        <Button loading={resetEmail.isLoading} type="submit">
          {t('auth.send')}
        </Button>

        <Link className="text-center w-full" to="/login">
          <Button className="w-full mt-3" style={{ fontSize: '16px' }} variant="subtle">
            {t('goBack')}
          </Button>
        </Link>
      </div>

      <div className="rounded-xl bg-[#FBD1A1] p-6 mt-5 flex justify-center items-center">
        <img
          alt="help-text"
          className="w-10 h-[50px] object-contain"
          src={`/images/illustrations/shield.png`}
        />
        <span className="text-[#32384E] text-sm ml-6">{t('auth.resetPasswordHelpMessage')}</span>
      </div>
    </form>
  );
};

export default ResetEmailForm;
