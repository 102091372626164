import { Navigate } from 'react-router-dom';

import { api } from '@chess-class/sandbox';
import { storageKeys } from '@chess-class/sandbox/consts';

const AuthRoute = ({ auth, children }: { auth: boolean; children: JSX.Element }) => {
  const me = api.user.studentAuth.useStudentMe();

  if (
    auth &&
    (!localStorage.getItem(storageKeys.tokenAccess) || me.error?.response?.status == 401)
  ) {
    if (!localStorage.getItem(storageKeys.tokenAccess)) {
      return <Navigate to="/login" />;
    }
  }

  if (!auth && localStorage.getItem(storageKeys.tokenAccess)) {
    return <Navigate to="/" />;
  }

  return children;
};

export default AuthRoute;
