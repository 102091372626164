import type { FC } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Divider, Modal } from '@mantine/core';

import { useStudentContext } from '~/context/StudentContext';

type ExecuteHomeworkModalProps = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ExecuteHomeworkModal: FC<ExecuteHomeworkModalProps> = ({ id, isOpen, onClose }) => {
  const { t } = useTranslation();
  const { isFirstClass } = useStudentContext();

  return (
    <Modal centered onClose={onClose} opened={isOpen} size={570}>
      <div className="mb-13 max-w-[400px] mx-auto mt-5 text-center font-semibold text-gray-600 text-base">
        {t(
          isFirstClass
            ? 'homeworksPage.startIndependentMessage'
            : 'homeworksPage.startHomeworkMessage',
        )}
      </div>

      <Divider className="mb-8 -mx-6" />

      <div className="grid grid-cols-2 gap-4">
        <Link
          onClick={() =>
            ReactGA.event({
              action: 'start_homework',
              category: 'start_homework',
              label: 'start_homework',
            })
          }
          to={`/homework/complete/${id}`}
        >
          <Button className="w-full" variant="primary">
            {t('proceed')}
          </Button>
        </Link>
        <Button onClick={onClose} variant="outline">
          {t('cancel')}
        </Button>
      </div>
    </Modal>
  );
};
