import { Route, Routes } from 'react-router-dom';

import LessonsPage from '~/modules/lessons/components/pages/LessonsPage';

import LessonPage from '~mLessons/components/pages/LessonPage';

export const LessonsRouter = () => {
  return (
    <Routes>
      <Route element={<LessonsPage />} index />
      <Route element={<LessonPage />} path="/:id" />
    </Routes>
  );
};
