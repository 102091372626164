import { useTranslation } from 'react-i18next';
import { Menu, Skeleton, UnstyledButton } from '@mantine/core';

import { api } from '@chess-class/sandbox';
import { useLogout } from '@chess-class/sandbox/hooks';
import { SignOutIcon } from '@chess-class/sandbox/icons';

import ProfileAvatar from '~/components/atoms/ProfileAvatar';

export default function ProfileDropdown() {
  const meQuery = api.user.studentAuth.useStudentMe();
  const { t } = useTranslation();
  const { logout } = useLogout();

  if (meQuery.isLoading || meQuery.isError) {
    return (
      <div className="flex gap-3 items-center">
        <Skeleton circle height={40} />
        <div className="flex flex-col gap-2 items-center">
          <Skeleton height={8} radius="xl" width={80} />
          <Skeleton height={8} radius="xl" />
          <Skeleton height={8} radius="xl" />
        </div>
      </div>
    );
  }

  return (
    <>
      <Menu
        classNames={{ dropdown: 'min-w-[200px] lg:min-w-[300px]' }}
        position="bottom-end"
        withArrow
      >
        <Menu.Target>
          <UnstyledButton className="flex gap-3 items-center">
            <ProfileAvatar showCaret />
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label className="flex items-center gap-2 p-4">
            <ProfileAvatar />
          </Menu.Label>

          <Menu.Divider />

          <Menu.Item icon={<SignOutIcon className="text-gray-300" />} onClick={logout}>
            {t('exit')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
