import { Route, Routes } from 'react-router-dom';

import { ProfilePage } from './pages/ProfilePage';

export const ProfileRouter = () => {
  return (
    <Routes>
      <Route element={<ProfilePage />} index />
    </Routes>
  );
};
