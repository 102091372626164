import { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { LessonsRouter } from '~/modules/lessons/LessonsRouter';

import { HomeworkRouter } from '~mHomework/HomeworkRouter';
import { ProfileRouter } from '~mProfile/ProfileRouter';

import IndexRoute from './components/molecules/routes/IndexRoute';
import { StudentContext } from './context/StudentContext';
import { initializeReactGA, logPageView, setUser } from './googleAnalytics';
import AdminLayout from './layouts/AdminLayout';
import AuthLayout from './layouts/AuthLayout';
import ChangePasswordPage from './modules/auth/components/pages/ChangePasswordPage';
import ConfirmEmailPage from './modules/auth/components/pages/ConfirmEmailPage';
import LoginPage from './modules/auth/components/pages/LoginPage';
import ResetEmailPage from './modules/auth/components/pages/ResetEmailPage';
import { ClassQuizRouter } from './modules/classQuiz/ClassQuizRouter';

const App = () => {
  const { school } = useContext(StudentContext);

  useEffect(() => {
    initializeReactGA();
    logPageView();
  }, []);

  useEffect(() => {
    if (school) {
      setUser(school?.educationFormat);
    }
  }, [school]);

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route element={<LoginPage />} path="login" />
        <Route element={<ResetEmailPage />} path="/reset/email" />
        <Route element={<ChangePasswordPage />} path="/reset-password" />
        <Route element={<ConfirmEmailPage />} path="/confirm/email" />
      </Route>

      <Route element={<AdminLayout />} path="*">
        <Route element={<IndexRoute />} index />

        <Route element={<ClassQuizRouter />} path="classQuizzes/*" />
        <Route element={<ProfileRouter />} path="profile/*" />
        <Route element={<HomeworkRouter />} path="homework/*" />
        <Route element={<LessonsRouter />} path="lessons/*" />
      </Route>
    </Routes>
  );
};

export default App;
