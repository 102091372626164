import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';
import { InputBase, Select, TextInput } from '@mantine/core';

import { getGender } from '@chess-class/sandbox';

import { DatePickerInput } from '~/components/atoms/date/DatePickerInput';
import { StudentContext } from '~/context/StudentContext';
import { useUserFormContext } from '~/context/UserProfileFormContext';

const ProfileForm: FC = () => {
  const { t } = useTranslation();
  const form = useUserFormContext();
  const { school } = useContext(StudentContext);

  return (
    <>
      <div className="grid md:grid-cols-3 gap-4">
        <div>
          <TextInput
            label={t('profilePage.lastName')}
            name="email"
            withAsterisk
            {...form.getInputProps('lastName')}
          />
        </div>
        <div>
          <TextInput
            label={t('profilePage.firstName')}
            name="firstName"
            withAsterisk
            {...form.getInputProps('firstName')}
          />
        </div>
        <div>
          <TextInput
            label={t('profilePage.middleName')}
            name="middleName"
            {...form.getInputProps('middleName')}
          />
        </div>
        <div>
          <TextInput
            label={t('profilePage.iin')}
            name="iin"
            {...form.getInputProps('iin')}
            maxLength={12}
            placeholder="123456789012"
          />
        </div>

        <div>
          <DatePickerInput
            className="col-span-2"
            label={t('profilePage.birthDate')}
            name="birthDate"
            valueFormat="DD.MM.YYYY"
            withAsterisk
            {...form.getInputProps('birthDate')}
          />
        </div>
        <div>
          <Select
            className="col-span-2"
            data={getGender().map((gender) => ({
              label: gender.label,
              value: gender.value,
            }))}
            label={t('profilePage.gender')}
            name="gender"
            {...form.getInputProps('gender')}
            placeholder={t('profilePage.genderPlaceholder')}
          />
        </div>
        <div>
          <TextInput
            label={school?.educationFormat == 'GENERAL' ? t('class') : t('group')}
            name="groupId"
            {...form.getInputProps('groupName')}
            disabled
          />
        </div>

        <div>
          <TextInput
            label={t('profilePage.email')}
            name="email"
            withAsterisk
            {...form.getInputProps('email')}
            placeholder={t('auth.enterEmail')}
          />
        </div>
        <div>
          <InputBase
            className="col-span-3"
            component={IMaskInput}
            label={t('profilePage.phone')}
            left="+7"
            mask="+7 000 000 00 00"
            name="phone"
            {...form.getInputProps('phone')}
          />
        </div>
      </div>
    </>
  );
};

export default ProfileForm;
