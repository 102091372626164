import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-NDDQF599MW';

const initializeReactGA = () => {
  ReactGA.initialize(TRACKING_ID);
};

const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Custom Title' });
};

const setUser = (educationForm) => {
  ReactGA.gtag('set', 'user_properties', {
    account_verified: true,
  });

  ReactGA.gtag('set', 'user_properties', {
    educationFormat: educationForm,
    role: 'Ученик',
  });
};

export { initializeReactGA, logPageView, setUser };
