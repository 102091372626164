import { useContext, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { Button, Skeleton } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';

import {
  api,
  IClassQuizSessionDetailed,
  IEnumReviewContentType,
  IHomeworkSession,
  IStudentClassQuizCompleteRequest,
} from '@chess-class/sandbox';
import { NotFoundResult } from '@chess-class/sandbox/components';
import { queryKeys } from '@chess-class/sandbox/consts';
import { IllustrationGemIcon } from '@chess-class/sandbox/icons';
import {
  openAnswerResultModal,
  openAssignmentInstruction,
  openTooManyAttemptsSingleExercise,
} from '@chess-class/sandbox/utils';

import { ReviewContentModal } from '~/components/organisms/ReviewContentModal';
import { PageMeta } from '~/context/PageMetaContext';
import { StudentContext, useStudentContext } from '~/context/StudentContext';

import AttemptsTooltip from '~mHomework/components/atoms/AttemptsTooltip';
import QuizStudioFinishBox from '~mHomework/components/molecules/QuizStudioFinishBox';
import QuizStudioHeader from '~mHomework/components/molecules/QuizStudioHeader';
import QuizStudioStartBox from '~mHomework/components/molecules/QuizStudioStartBox';
import StudioExerciseProblem from '~mHomework/components/molecules/StudioExerciseProblem';
import StudioExerciseProblemCustom from '~mHomework/components/molecules/StudioExerciseProblemCustom';
import StudioExerciseTest from '~mHomework/components/molecules/StudioExerciseTest';

export default function QuizStudioExercisePage({ isHomework }: { isHomework?: boolean }) {
  const { id = '' } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { isFirstClass } = useStudentContext();

  const [taskIndex, setTaskIndex] = useState(0);
  const [attempts, setAttempts] = useState(0);
  const [attemptsConsecutively, setAttemptsConsecutively] = useState(0);
  const [isFinishing, setIsFinishing] = useState(false);
  const [isStart, setIsStart] = useState(!isHomework);
  const [answers, setAnswers] = useState<Record<string, boolean | undefined | number>>({});
  const [checkedAnswer, setCheckedAnswer] = useState<boolean>(false);
  const [checkedAnswerIndex, setCheckedAnswerIndex] = useState<number>();
  const navigate = useNavigate();

  const setPartialAnswers = (keys: Record<string, boolean | number>) => {
    setAnswers((prev) => ({ ...prev, ...keys }));
  };

  const { school, student } = useContext(StudentContext);

  const [isOpenRateContentModal, setIsOpenRateContentModal] = useState(false);

  const quizQuery = isHomework
    ? api.education.homework.useStudentHomework({ id })
    : api.education.classQuiz.useStudentClassQuiz({ id });

  const quiz = quizQuery.data?.data;
  const studentExercises = useMemo(
    () =>
      quiz?.studentExercises?.sort((a, b) => {
        if (a.exerciseId < b.exerciseId) {
          return -1;
        }
        if (a.exerciseId > b.exerciseId) {
          return 1;
        }
        return 0;
      }),
    [quiz?.studentExercises],
  );
  const exerciseQuery = api.content.exercises.useExercise({
    id: studentExercises?.[taskIndex]?.exerciseId,
  });
  const exercise = exerciseQuery.data?.data;

  const mutateAnswer = isHomework
    ? api.education.homework.useStudentHomeworkSubmitExercise()
    : api.education.classQuiz.useStudentClassQuizSubmitExercise();
  const mutateComplete = isHomework
    ? api.education.homework.useStudentHomeworkComplete()
    : api.education.classQuiz.useStudentClassQuizComplete();

  const solvedRightCount = useMemo(
    () => studentExercises?.filter(({ status }) => status == 'SOLVED').length || 0,
    [studentExercises],
  );

  const reviewsQuery = api.reviews.reviews.useQueryStudentCoachReview({
    contentId: quiz?.id ?? '',
    contentType: IEnumReviewContentType.TEST,
    educationFormat: school?.educationFormat ?? 'PRIVATE',
    userId: student?.id ?? '',
  });

  const reviews = reviewsQuery.data?.data;

  const isDisabled = useMemo(() => {
    if (mutateAnswer.isLoading || mutateComplete.isLoading) {
      return true;
    }
    if (answers[taskIndex] === true) {
      return true;
    }

    if (attempts == quiz?.totalAttempts) {
      return true;
    }

    return answers[taskIndex] !== undefined && Object.values(answers).includes(undefined);
  }, [taskIndex, attempts, answers]);

  const getButtonTitle = () => {
    if ((studentExercises?.length || 0) > taskIndex + 1) {
      return answers[taskIndex] == undefined && attempts != quiz?.totalAttempts
        ? t('answer')
        : t('next');
    } else {
      return t('finish');
    }
  };

  const onAnswer = (passed?: boolean, answerChoice = 0) => {
    if (!isDisabled) {
      mutateAnswer
        .mutateAsync({
          answerChoice: answerChoice,
          classQuizSessionId: id,
          exerciseId: exercise?.id || '',
          homeWorkSessionId: id,
          solved: passed ?? false,
        })
        .then(() => {
          quizQuery.refetch();
          setPartialAnswers({ [taskIndex]: passed || answerChoice });
          setAttempts((prev) => prev + 1);
          setTimeout(() => {
            setTaskIndex((prev) => prev + 1);
            setCheckedAnswerIndex(undefined);
            setAttemptsConsecutively(0);
            if ((studentExercises?.length || 0) <= taskIndex + 1) {
              setIsFinishing(true);
              ReactGA.event({
                action: 'homework_done',
                category: 'homework_done',
                label: 'homework_done',
              });
              quizQuery.refetch();
            }
          }, 1500);
          if (!passed && attemptsConsecutively == 1) {
            openTooManyAttemptsSingleExercise();
          } else {
            openAnswerResultModal(passed ? 'win' : 'fail');
          }
          setAttemptsConsecutively((prev) => prev + 1);
        });
    } else {
      setTaskIndex((prev) => prev + 1);
      if ((studentExercises?.length || 0) <= taskIndex + 1) {
        setIsFinishing(true);
        quizQuery.refetch();
      }
    }
  };

  useEffect(() => {
    if (quiz) {
      setAttempts(quiz.attempts);
      if (!quiz.attempts && !isStart) {
        openAssignmentInstruction();
      }
      if (!isFinishing) {
        setIsFinishing(quiz.status == 'FINISHED');
      }
    }
  }, [quiz?.attempts, isStart]);

  useEffect(() => {
    if (studentExercises) {
      setAnswers(
        studentExercises.reduce((answers, { status }, index) => {
          switch (status) {
            case 'SOLVED':
              answers[index] = true;
              break;
            case 'FAILED':
              answers[index] = false;
              break;
            default:
              answers[index] = undefined;
          }
          return answers;
        }, {}),
      );
      studentExercises.forEach(({ exerciseId }) => {
        queryClient.prefetchQuery({
          queryFn: () => api.content.exercises.helpers.getExercise({ id: exerciseId }),
          queryKey: [queryKeys.contentExercise, { id: exerciseId }],
        });
      });
    }
  }, [studentExercises]);

  return (
    <div className={clsx('absolute bg-[#DBDBEA] w-full left-0 top-0 flex flex-col', 'min-h-full')}>
      <PageMeta
        selectedMenuKeys={[isHomework ? 'homework' : 'classQuiz']}
        title={!isHomework ? t('assignment') : isFirstClass ? t('independentWork') : t('homework')}
      />

      <QuizStudioHeader
        isFirstClass={isFirstClass}
        isHomework={isHomework}
        order={
          isHomework
            ? (quiz as IHomeworkSession)?.orderByGroup
            : (quiz as IClassQuizSessionDetailed)?.classQuiz.orderByClass
        }
      />

      {quizQuery.isLoading ? (
        <Skeleton className="w-2/3 mx-auto mt-10 h-2/3 min-h-[400px]" />
      ) : !!quiz && isStart ? (
        <QuizStudioStartBox onStart={() => setIsStart(false)} quiz={quiz} />
      ) : !!quiz && isFinishing ? (
        <QuizStudioFinishBox
          isLoading={
            mutateAnswer.isLoading ||
            mutateComplete.isLoading ||
            quizQuery.isFetching ||
            quizQuery.isLoading
          }
          onCancel={() => {
            setIsFinishing(false);
            setTaskIndex(0);
          }}
          onExitClick={() => {
            if (school?.educationFormat == 'PRIVATE') {
              if (reviews && reviews.find((r) => r.contentId == quiz.id)) {
                mutateComplete.mutate({ id: quiz?.id } as IStudentClassQuizCompleteRequest);
                navigate('../../');
              } else {
                setIsOpenRateContentModal(true);
              }
            } else {
              mutateComplete.mutate({ id: quiz?.id } as IStudentClassQuizCompleteRequest);
              navigate('../../');
            }
          }}
          quiz={quiz}
          solvedRightCount={solvedRightCount}
        />
      ) : studentExercises?.length ? (
        <div className="py-5 px-5 md:py-10 md:px-10 lg:px-20 grid h-auto gap-4 justify-center">
          <div className="flex gap-6 justify-between items-center">
            <div className="flex flex-wrap gap-2">
              {studentExercises.map((ex, index) => {
                const answerStatus =
                  answers[index] === true
                    ? 'win'
                    : answers[index] === undefined
                      ? 'where_is_answer_lebovski'
                      : 'fail';

                return (
                  <Button
                    classNames={{
                      label: clsx(
                        answerStatus !== 'where_is_answer_lebovski'
                          ? 'text-white'
                          : taskIndex == index
                            ? 'text-primary'
                            : '',
                      ),
                      root: clsx(
                        'p-0 w-8 h-8 rounded-full',
                        taskIndex == index
                          ? answerStatus == 'fail'
                            ? 'border-red-700'
                            : answerStatus === 'win'
                              ? 'border-green-700'
                              : 'border-primary'
                          : 'border-gray-200',
                        answerStatus == 'fail'
                          ? 'bg-red-105'
                          : answerStatus == 'win'
                            ? 'bg-green-105'
                            : '',
                      ),
                    }}
                    disabled={
                      index != 0 && answers[index] === undefined && answers[index - 1] === undefined
                    }
                    key={`question-${index}`}
                    onClick={() => {
                      setAttemptsConsecutively(0);
                      setCheckedAnswer(false);
                      setCheckedAnswerIndex(undefined);
                      setTaskIndex(index);
                    }}
                    variant="outline"
                  >
                    {index + 1}
                  </Button>
                );
              })}
            </div>
            <div className="flex gap-1.5 items-center shrink-0">
              <IllustrationGemIcon className="text-cyan-101" />

              <span className="text-gray-400 font-semibold relative">
                <span className="text-primary-800">
                  {`${t('attempt')} ${attempts} ${language == 'kz' ? '/ ' : ' из '}`}
                </span>
                {quiz?.totalAttempts}
                {!attempts && (
                  <div
                    className="transition-all duration-500 ease-in-out absolute w-[130%] aspect-square left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-[501] shadow-[0_0_0_max(150vh,150vw)_rgba(50,56,78,0.70)] rounded-full opacity-0"
                    id="attempts-highlight"
                  />
                )}
              </span>
              <AttemptsTooltip />
            </div>
          </div>

          <div className="flex gap-8 min-w-0 flex-col lg:flex-row">
            {!!exercise && exercise.type == 'PROBLEM' && (
              <StudioExerciseProblem
                disabled={isDisabled}
                exercise={exercise}
                onAnswer={(correct) => {
                  onAnswer(correct);
                }}
                solved={answers[taskIndex] === true}
              />
            )}
            {exercise && exercise.type == 'CUSTOM_PROBLEM' && exercise.customProblem && (
              <StudioExerciseProblemCustom
                disabled={isDisabled}
                exercise={exercise}
                onAnswer={(correct) => {
                  onAnswer(correct);
                }}
              />
            )}
            {exercise && exercise.type == 'TEST' && (
              <StudioExerciseTest
                defaultChecked={studentExercises?.[taskIndex]?.answerChoice}
                disabled={isDisabled}
                exercise={exercise}
                onAnswer={(correct, index) => {
                  setCheckedAnswer(correct);
                  setCheckedAnswerIndex(index);
                }}
              />
            )}
          </div>

          <div className="flex gap-6 grow-0 shrink-0 mt-8">
            <Button
              classNames={{
                label: answers[taskIndex] === undefined ? 'text-gray-200' : '',
                root: 'px-20 w-full lg:w-auto',
              }}
              disabled={answers[taskIndex] == undefined && checkedAnswerIndex == undefined}
              loading={
                mutateAnswer.isLoading ||
                mutateComplete.isLoading ||
                quizQuery.isFetching ||
                quizQuery.isLoading
              }
              onClick={() => {
                setAttemptsConsecutively(0);
                if (isDisabled) {
                  setTaskIndex((prev) => prev + 1);
                  setCheckedAnswer(false);
                  setCheckedAnswerIndex(undefined);
                } else {
                  onAnswer(checkedAnswer, checkedAnswerIndex);
                }
              }}
              variant="primary"
            >
              {getButtonTitle()}
            </Button>
          </div>
        </div>
      ) : (
        !exercise && <NotFoundResult title="Задания не найдены" />
      )}
      <ReviewContentModal
        contentId={quiz?.id ?? ''}
        contentType={IEnumReviewContentType.TEST}
        isOpen={isOpenRateContentModal}
        onClose={() => {
          setIsOpenRateContentModal(false);
          navigate('../../');
          mutateComplete.mutate({ id: quiz?.id } as IStudentClassQuizCompleteRequest);
        }}
        onFinish={() => mutateComplete.mutate({ id: quiz?.id } as IStudentClassQuizCompleteRequest)}
        title={t('review.reviewTest')}
      />
    </div>
  );
}
