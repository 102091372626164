import type { FC } from 'react';
import clsx from 'clsx';
import { Pagination as MantinePagination, UnstyledButton } from '@mantine/core';

type PaginationProps = {
  className?: string;
  disabled?: boolean;
  onFilter: ({ page, size }: { page?: number; size?: number }) => void;
  size: number;
  total: number;
  value: number;
};

export const Pagination: FC<PaginationProps> = ({
  className,
  disabled = false,
  onFilter,
  size = 10,
  total,
  value,
}) => {
  return (
    <div className={`flex justify-between ${className}`}>
      <MantinePagination
        classNames={{
          control:
            'border-gray-200 data-[active=true]:bg-white data-[active=true]:border-primary data-[active=true]:text-primary',
        }}
        disabled={disabled}
        onChange={(page) => onFilter({ page: page - 1 })}
        total={total || 1}
        value={value}
      />
      <div className="md:flex gap-3 h-fit hidden">
        {[10, 20, 50].map((itemSize) => (
          <UnstyledButton
            className={clsx(
              'p-2 h-fit',
              size == itemSize ? 'border border-solid bg-white border-gray-300 rounded' : '',
            )}
            disabled={disabled}
            key={`size-${itemSize}`}
            onClick={() => onFilter({ size: itemSize })}
          >
            {itemSize}
          </UnstyledButton>
        ))}
      </div>
    </div>
  );
};
