import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePickerInput as MantineDatePickerInput, DatePickerInputProps } from '@mantine/dates';

export const DatePickerInput: FC<DatePickerInputProps> = (props) => {
  const {
    i18n: { language },
  } = useTranslation();

  return <MantineDatePickerInput locale={language} {...props} />;
};
