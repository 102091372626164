import { type FC, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import i18n, { t } from 'i18next';
import { Menu } from '@mantine/core';

import { api } from '@chess-class/sandbox';

import { StudentContext } from '~/context/StudentContext';

type ChaptersPopoverProps = {
  chapterIds: string[];
};

export const ChaptersPopover: FC<ChaptersPopoverProps> = ({ chapterIds }) => {
  const chaptersQuery = api.guideline.guidelineChapters.useGuidelineChapters({
    enabled: !!chapterIds.length,
    ids: chapterIds,
  });
  const chapters = chaptersQuery.data?.data.content ?? [];
  const { school } = useContext(StudentContext);
  const sortedChapters =
    school?.educationFormat == 'GENERAL' ? chapters : chapters?.sort((a, b) => a.numId - b.numId);

  return (
    <Menu.Dropdown>
      {sortedChapters?.map((chapter) => (
        <Menu.Item key={chapter.id}>
          <NavLink to={`/lessons/${chapter.id}`}>
            <div className="flex items-center gap-3 text-base">
              <span className="text-primary-400 font-semibold">
                {t('chapter')}{' '}
                {school?.educationFormat == 'GENERAL' ? chapter.priority : chapter.numId}
              </span>
              <span className="text-gray-500">
                {chapter.translations ? chapter.translations?.[i18n.language]?.title : '-'}
              </span>
            </div>
          </NavLink>
        </Menu.Item>
      ))}
    </Menu.Dropdown>
  );
};
