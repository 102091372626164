import { Route, Routes } from 'react-router-dom';

import { QuizDetailsPage } from '~mHomework/components/pages/QuizDetailsPage';
import QuizStudioExercisePage from '~mHomework/components/pages/QuizStudioExercisePage';

import { ClassQuizzesPage } from './pages/ClassQuizzesPage';

export const ClassQuizRouter = () => {
  return (
    <Routes>
      <Route element={<ClassQuizzesPage />} index />
      <Route element={<QuizDetailsPage />} path="/:id" />
      <Route element={<QuizStudioExercisePage />} path="/complete/:id" />
    </Routes>
  );
};
