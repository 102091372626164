import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { ActionIcon, Button } from '@mantine/core';

import { ArrowBackIcon, FullScreenIcon } from '@chess-class/sandbox/icons';

export default function QuizStudioHeader({
  isFirstClass,
  isHomework,
  order,
}: {
  isFirstClass?: boolean;
  isHomework?: boolean;
  order?: number;
}) {
  return (
    <div className="h-20 flex shrink-0 items-center gap-6 py-5 px-5 md:py-10 md:px-10 lg:px-20 bg-white border-b shadow">
      <Link relative="path" to="../..">
        <Button
          className="font-normal pl-3 border-gray-200 rounded-xl"
          leftIcon={<ArrowBackIcon className="w-4 h-4" />}
          variant="outline"
        >
          <span className="hidden md:block">{t('exit')}</span>
          <span className="md:hidden">{t('back')}</span>
        </Button>
      </Link>

      <div className="flex items-center flex-grow gap-4">
        <img
          className="w-9 h-9 object-contain rounded-lg bg-[#BBCBF5] object-bottom"
          src="/images/illustrations/guideline-chapter.png"
        />
        <span className="text-base font-bold text-primary-800">
          {!isHomework ? t('assignment') : isFirstClass ? t('independentWork') : t('homework')}
          {order ? ` #${order}` : <LoadingOutlined />}
        </span>
      </div>
      <ActionIcon
        className="w-12 h-12 rounded-lg border-gray-200 hidden md:flex"
        onClick={() => {
          if (document.fullscreenElement) {
            document.exitFullscreen();
          } else {
            document.body.requestFullscreen();
          }
        }}
        variant="default"
      >
        <FullScreenIcon />
      </ActionIcon>
    </div>
  );
}
