import { useContext } from 'react';
import { t } from 'i18next';

import { api } from '@chess-class/sandbox';
import { ChevronDownIcon } from '@chess-class/sandbox/icons';

import { StudentContext } from '~/context/StudentContext';

export default function ProfileAvatar({ showCaret }: { showCaret?: boolean }) {
  const meQuery = api.user.studentAuth.useStudentMe();
  const { school } = useContext(StudentContext);
  return (
    <div className="flex gap-4 items-center">
      <img
        alt="Profile image"
        className="h-12 w-12 object-contain"
        src={`/images/illustrations/${meQuery?.data?.gender?.toLowerCase()}.png`}
      />

      <div className="flex flex-col justify-center">
        <div className="flex items-center gap-2">
          <span className="font-semibold text-sm capitalize text-[#4F5B67]">
            {`${meQuery.data?.firstName} ${meQuery.data?.lastName}`}
          </span>
          {showCaret && <ChevronDownIcon className="rotate-180 mr-5" height={12} width={12} />}
        </div>

        <span className="text-gray-600 text-xs">
          {meQuery.data?.groupName} {school?.educationFormat == 'GENERAL' ? t('class') : t('group')}
        </span>
      </div>
    </div>
  );
}
