import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { Button, Modal, Skeleton, Switch, Title } from '@mantine/core';

import { IEnumClassQuizStudentExerciseStatusKeys, IExercise } from '@chess-class/sandbox';
import { ChessboardExercise, ExerciseCustomProblem } from '@chess-class/sandbox/components';

import HomeworkStatusBox from '~mHomework/components/atoms/HomeworkStatusBox';

export default function ExerciseDetailsModal({
  exercise,
  isBackButtonDisabled,
  isNextButtonDisabled,
  isOpen,
  isView,
  onBackButtonClick,
  onCancel,
  onNextButtonClick,
  status,
}: {
  exercise?: IExercise;
  isBackButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
  isOpen: boolean;
  isView?: boolean;
  onBackButtonClick: () => void;
  onCancel: () => void;
  onNextButtonClick: () => void;
  status?: IEnumClassQuizStudentExerciseStatusKeys;
}) {
  const [showAnswer, setShowAnswer] = useState(false);

  useEffect(() => {
    setShowAnswer(false);
  }, [exercise]);

  const [shouldRender, setShouldRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const startTimer = () => {
    setIsLoading(true);
    timeoutIdRef.current = setTimeout(() => {
      setShouldRender(true);
      setIsLoading(false);
    }, 1000);
  };

  const clearTimer = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
  };

  useEffect(() => {
    if (isOpen) {
      startTimer();
    } else {
      clearTimer();
      setShouldRender(false);
    }

    return () => {
      clearTimer();
    };
  }, [isOpen]);

  return (
    <Modal
      classNames={{
        body: 'p-4 md:p-8 bg-gray-116',
        header: 'p-4 md:p-6 gap-4 md:gap-8',
        inner: exercise?.customProblem ? 'p-4 md:p-8' : '',
        root: 'max-w-full',
      }}
      onClose={onCancel}
      opened={isOpen}
      size="95%"
      title={
        <div className="flex flex-row gap-3 items-center justify-between w-full">
          <div className="flex items-center">
            <img
              alt="Test"
              className="w-12 h-12 mr-6 object-contain"
              src="/images/illustrations/problem-img.png"
            />{' '}
            <Title size={24}>
              {t('exercise')} #{exercise?.numId}
            </Title>
          </div>
          {!isView && (
            <Switch
              checked={showAnswer}
              classNames={{
                body: 'flex flex-col md:flex-row items-center text-center gap-2',
                label: 'p-0 md:pr-2',
              }}
              label={t('studiosPage.showAnswer')}
              labelPosition="left"
              onChange={() => {
                if (showAnswer) {
                  lichess.board?.hideSolution();
                  setShowAnswer(false);
                } else {
                  lichess.board?.showSolution();
                  setShowAnswer(true);
                }
              }}
            />
          )}
          {status && <HomeworkStatusBox status={status} />}
        </div>
      }
    >
      {!shouldRender && (!exercise || isLoading) && (
        <div className="flex flex-col gap-10 items-center">
          <div className="flex lg:flex-row flex-wrap gap-8 w-full items-center lg:items-start lg:justify-center">
            <Skeleton className="w-full lg:w-auto flex-grow aspect-square" radius="md" />
            <div className="w-[500px] flex flex-col gap-4 m-auto lg:m-0">
              <Skeleton className="w-full h-20" />
              <Skeleton className="w-full h-20" />
            </div>
          </div>
        </div>
      )}
      {!!exercise?.problem && shouldRender && (
        <ChessboardExercise
          exercise={exercise}
          isView={isView}
          sidebar={{ moveActions: true, solutionBox: { initialShowMoves: false, show: true } }}
        />
      )}
      {!!exercise?.customProblem && shouldRender && (
        <ExerciseCustomProblem customProblem={exercise.customProblem!} sidebar={{ show: true }} />
      )}
      <div className="flex flex-row items-end justify-center mt-8">
        <Button
          className="w-44 mr-2"
          disabled={isBackButtonDisabled}
          onClick={onBackButtonClick}
          variant="outline"
        >
          {t('back')}
        </Button>
        <Button className="w-44" disabled={isNextButtonDisabled} onClick={onNextButtonClick}>
          {t('next')}
        </Button>
      </div>
    </Modal>
  );
}
