import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, PasswordInput, TextInput } from '@mantine/core';

import { useUserFormContext } from '~/context/UserProfileFormContext';

export default function ChangePasswordForm({
  isReset,
  setIsReset,
}: {
  isReset: boolean;
  setIsReset: (isReset: boolean) => void;
}) {
  const { t } = useTranslation();
  const form = useUserFormContext();

  useEffect(() => {
    if (!isReset) {
      form.setFieldValue('newPassword', '');
      form.setFieldValue('confirmPassword', '');
    }
  }, [isReset]);

  return (
    <>
      <div className="mb-6">
        <span className="text-[#11122C] font-bold text-lg">
          {t('profilePage.loginAndPassword')}
        </span>
      </div>
      <div className="grid md:grid-cols-2 gap-4">
        <div>
          <TextInput
            label={t('auth.login')}
            name="login"
            required
            {...form.getInputProps('email')}
            description={t('profilePage.loginHelpText')}
          />
        </div>
        <div className="flex justify-center">
          {!isReset && (
            <Button
              className="py-4 w-full mt-2 self-center bg-[#E4E6ED] text-[#11122C] font-normal hover:bg-[#E4E6ED]"
              onClick={() => setIsReset(true)}
              variant="primary"
            >
              {t('auth.changePassword')}
            </Button>
          )}
        </div>
        {isReset && (
          <>
            <div>
              <PasswordInput
                label={t('auth.newPassword')}
                withAsterisk
                {...form.getInputProps('newPassword')}
              />
            </div>
            <div>
              <PasswordInput
                label={t('auth.confirmPassword')}
                withAsterisk
                {...form.getInputProps('confirmPassword')}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
