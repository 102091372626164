import { Route, Routes } from 'react-router-dom';

import { QuizDetailsPage } from '~mHomework/components/pages/QuizDetailsPage';
import QuizStudioExercisePage from '~mHomework/components/pages/QuizStudioExercisePage';

import { HomeworksPage } from './components/pages/HomeworksPage';

export const HomeworkRouter = () => {
  return (
    <Routes>
      <Route element={<HomeworksPage />} index />
      <Route element={<QuizDetailsPage isHomework />} path="/:id" />
      <Route element={<QuizStudioExercisePage isHomework />} path="/complete/:id" />
    </Routes>
  );
};
