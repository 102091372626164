import { FC, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Skeleton, Tabs } from '@mantine/core';

import {
  api,
  getHomeworkStatuses,
  IEnumClassQuizStatusKeys,
  IEnumHomeworkStatus,
  IStudentClassQuizzesRequest,
} from '@chess-class/sandbox';
import { NotFoundResult } from '@chess-class/sandbox/components';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { Pagination } from '~/components/molecules/Pagination';
import { PageMeta } from '~/context/PageMetaContext';

import { QuizBox } from '~mHomework/components/organisms/QuizBox';

import { classQuizFilters } from '../consts/filters/classQuizFilters';
import {
  classQuizPageActions,
  IClassQuizPageActions,
} from '../consts/pageActions/classQuizPageActions';

export const ClassQuizzesPage: FC = () => {
  const { t } = useTranslation();

  const { filtered, onFilter, setFiltered } = useUrlFilteredActioned<
    IStudentClassQuizzesRequest,
    IClassQuizPageActions
  >(classQuizFilters, classQuizPageActions);

  const classQuizzesQuery = api.education.classQuiz.useStudentClassQuizzes({
    ...filtered,
    status: filtered.status == 'ISSUED' ? ['ISSUED', 'ACTIVE'] : filtered.status,
  });

  const classQuizzes = classQuizzesQuery.data?.data.content;

  const classQuizTemplate = useMemo(() => {
    return (
      <>
        <div className="flex flex-col gap-5">
          {classQuizzesQuery.isLoading ? (
            <Skeleton className="rounded-xl" height="223" />
          ) : classQuizzes && classQuizzes.length ? (
            classQuizzes.map((classQuiz) => (
              <QuizBox key={classQuiz.id} quiz={classQuiz} showActions showGrade showStatus />
            ))
          ) : (
            <NotFoundResult subTitle="" title={t('assignmentsPage.noAssignment')} />
          )}
        </div>

        <Pagination
          className="mt-10"
          disabled={classQuizzesQuery.isFetching || classQuizzes?.length === 0}
          onFilter={onFilter}
          size={filtered.size ?? 10}
          total={classQuizzesQuery.data?.data.totalPages ?? 0}
          value={(Number(filtered.page) ?? 0) + 1}
        />
      </>
    );
  }, [filtered, classQuizzes, classQuizzesQuery]);

  return (
    <>
      <PageMeta selectedMenuKeys={['classQuizzes']} title={t('assignment')} />

      <div className="overflow-hidden bg-primary-106 px-9 h-[120px] md:h-38 rounded-xl flex items-center justify-between bg-[url('../../../public/images/illustrations/assignment-cover.png')] bg-contain bg-right bg-no-repeat">
        <div className="flex flex-col md:gap-1 text-white">
          <span className="font-bold md:text-2xl text-white text-lg"> {t('assignment')}</span>
        </div>
      </div>

      <Tabs
        onTabChange={(status: IEnumClassQuizStatusKeys) => {
          setFiltered({
            page: 0,
            sort: '-classQuiz.orderByClass',
            status,
          });
          status == 'FINISHED' &&
            ReactGA.event({
              action: 'completed_homework',
              category: 'completed_homework',
              label: 'completed_homework',
            });
        }}
        value={
          Array.isArray(filtered.status) || filtered.status == 'ISSUED' ? 'ISSUED' : 'FINISHED'
        }
      >
        <Tabs.List className="mt-8 mb-5 flex gap-4">
          <Tabs.Tab key={IEnumHomeworkStatus.ISSUED} value="ISSUED">
            {getHomeworkStatuses().find((item) => item.value == IEnumHomeworkStatus.ISSUED)?.label}
          </Tabs.Tab>
          <Tabs.Tab key={IEnumHomeworkStatus.FINISHED} value="FINISHED">
            {
              getHomeworkStatuses().find((item) => item.value == IEnumHomeworkStatus.FINISHED)
                ?.label
            }
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="ISSUED">{classQuizTemplate}</Tabs.Panel>
        <Tabs.Panel value="FINISHED">{classQuizTemplate}</Tabs.Panel>
      </Tabs>
    </>
  );
};
