import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Image } from '@mantine/core';

import { IGroup, ISchool } from '@chess-class/sandbox';

type SchoolCardProps = {
  group: IGroup;
  school: ISchool;
};

const SchoolCard: FC<SchoolCardProps> = ({ group, school }) => {
  const { t } = useTranslation();

  const flatNesteObject = ({ nameKz, nameRu, parent }) => {
    return [
      i18n.language == 'kz' ? nameKz || nameRu : nameRu,
      ...(parent ? flatNesteObject(parent) : []),
    ];
  };

  const address = useMemo(() => {
    return school.location
      ? flatNesteObject({
          nameKz: school.location.nameKz,
          nameRu: school.location.nameRu,
          parent: school.location.parent,
        })
      : [];
  }, [school]);

  return (
    <>
      <div className="mb-6">
        <span className="text-[#11122C] font-bold text-lg"> {t('profilePage.schoolAndCoach')}</span>
      </div>
      <div className="items-center grid md:grid-cols-2">
        <div>
          <div className="flex flex-col md:flex-row justify-start items-start md:items-center">
            <Image
              alt="Profile image"
              className="mr-6 ml-0 object-contain"
              maw={100}
              mx="auto"
              src="/images/illustrations/school.png"
            />
            <div className="flex flex-col mt-4 md:mt-0">
              <h3 className="mb-0">{school.name}</h3>
              <span className="text-[#7D829A]">
                {address
                  .slice(0)
                  .reverse()
                  .map((item, index) => item + (index == address.length - 1 ? '' : ', '))}
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row items-center mt-6 md:mt-0">
            {group.coach ? (
              <div className="flex flex-col md:flex-row">
                <div className="h-[50px] w-[50px] mr-4 text-center flex flex-col items-center justify-center content-center rounded-[15px] border-2 border-gray-200 bg-gray-100">
                  <span className="uppercase text-primary text-[15px]">
                    {group?.coach?.firstName?.[0]}
                    {group?.coach?.lastName?.[0]}
                  </span>
                </div>
                <div className="flex flex-col mt-4 md:mt-0">
                  <h3 className="mb-0 text-[#4F5672] font-semibold text-base">{`${group.coach.firstName} ${group.coach.lastName}`}</h3>
                  <span className="text-[#7D829A]">{t('coach')}</span>
                </div>
              </div>
            ) : (
              <>
                <img
                  alt="User "
                  className="w-[50px] h-[50px] object-contain mr-4"
                  src="/images/illustrations/avatar-single.png"
                />
                <div className="flex flex-col ">
                  <h3 className="mb-0">{t('profilePage.withoutCoach')}</h3>
                  <span className="text-[#7D829A]">{t('profilePage.withoutCoachHelpText')}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolCard;
