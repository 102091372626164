import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, PasswordInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { api, IStudentAuthChangePasswordRequest } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import { PageMeta } from '~/context/PageMetaContext';

export type ChangePasswordLocationState = { email: string; password: string };

const ChangePasswordPage: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const routerState: ChangePasswordLocationState = location.state;

  const changePassword = api.user.studentAuth.useStudentChangePassword();

  const form = useForm<IStudentAuthChangePasswordRequest>({
    initialValues: {
      ...api.user.studentAuth.helpers.studentChangePasswordInitial,
      email: routerState.email,
      password: routerState.password,
    },
    validate: yupResolver(api.user.studentAuth.helpers.studentChangePasswordValidation),
  });

  useEffect(() => {
    if (!routerState.password || !routerState.email) {
      navigate('/login');
    }
  }, []);

  return (
    <>
      <PageMeta title={t('auth.updatePassword')} />

      <form
        className="w-full lg:w-2/3 max-w-sm"
        onSubmit={form.onSubmit((values) => {
          changePassword.mutateAsync({ ...values }).catch((error) => {
            if (
              error.response?.data.status == 400 &&
              error.response.data.message == 'PASSWORD_MUST_BE_DIFFERENT'
            ) {
              form.setFieldError('newPassword', t('auth.errorOldPassword'));
              form.setFieldError('confirmPassword', t('auth.errorOldPassword'));
            } else {
              notify('warning', t('tryAgainLater'));
            }
          });
        })}
      >
        <h2 className="font-bold mb-8 text-center text-3xl sm:text-4xl text-gray-900">
          {t('auth.welcome')}!
        </h2>

        <PasswordInput
          label={t('auth.newPassword')}
          maxLength={30}
          name="newPassword"
          required
          {...form.getInputProps('newPassword')}
          className="mt-5"
        />

        <PasswordInput
          label={t('auth.confirmPassword')}
          maxLength={30}
          name="confirmPassword"
          required
          {...form.getInputProps('confirmPassword')}
          className="mt-5"
        />

        <Button
          className="w-full mt-3 bg-color-[#11122C]"
          loading={changePassword.isLoading}
          type="submit"
          variant="primary"
        >
          {t('auth.updatePassword')}
        </Button>
      </form>
    </>
  );
};

export default ChangePasswordPage;
