import { IPageActions } from '@chess-class/sandbox';

enum ILessonPageActionsEnum {
  'view',
}

export enum ILessonPageActionTypesEnum {
  'test',
  'exercise',
}

export type ILessonPageActions = {
  action: keyof typeof ILessonPageActionsEnum;
  actionId: string;
  actionType: keyof typeof ILessonPageActionTypesEnum;
};

export const lessonPageActions: IPageActions<ILessonPageActions> = {
  action: {
    allowed: (value) => Object.keys(ILessonPageActionsEnum).includes(value),
    default: '',
  },
  actionId: { default: '' },
  actionType: { default: '' },
};
