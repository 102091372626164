import { IPageActions } from '@chess-class/sandbox';

import { ILessonPageActionTypesEnum } from '~/modules/lessons/consts/pageActions/ILessonPageActions';

enum IClassQuizzesPageActionsEnum {
  'view',
}

// enum IClassQuizPageActionTypesEnum {
//   //
// }

export type IClassQuizPageActions = {
  action: keyof typeof IClassQuizzesPageActionsEnum;
  actionId: string;
  actionType: keyof typeof ILessonPageActionTypesEnum;
};

export const classQuizPageActions: IPageActions<IClassQuizPageActions> = {
  action: {
    allowed: (value) => Object.keys(IClassQuizzesPageActionsEnum).includes(value),
    default: '',
  },
  actionId: { default: '' },
  actionType: { default: '' },
};
