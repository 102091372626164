import { type FC, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Skeleton, Tabs } from '@mantine/core';

import {
  api,
  getHomeworkStatuses,
  IEnumHomeworkStatus,
  IEnumStudentHomeworkStatusKeys,
  IStudentHomeworkRequest,
} from '@chess-class/sandbox';
import { NotFoundResult } from '@chess-class/sandbox/components';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { Pagination } from '~/components/molecules/Pagination';
import { PageMeta } from '~/context/PageMetaContext';
import { useStudentContext } from '~/context/StudentContext';

import { QuizBox } from '~mHomework/components/organisms/QuizBox';

import { homeworkFilters } from '../../consts/filters/homeworkFilters';
import {
  homeworkPageActions,
  IHomeworkPageActions,
} from '../../consts/pageActions/homeworkPageActions';
import { ExecuteHomeworkModal } from '../molecules/ExecuteHomeworkModal';

export const HomeworksPage: FC = () => {
  const { t } = useTranslation();
  const { actioned, clearParams, filtered, onFilter, setActioned, setFiltered } =
    useUrlFilteredActioned<IStudentHomeworkRequest, IHomeworkPageActions>(
      homeworkFilters,
      homeworkPageActions,
    );

  const { isFirstClass } = useStudentContext();

  const homeworksQuery = api.education.homework.useStudentHomeworks({
    ...filtered,
    status: filtered.status,
  });

  const homeworksData = homeworksQuery.data?.data.content;

  const homeworksTemplate = useMemo(() => {
    return (
      <div className="flex flex-col gap-5">
        {homeworksQuery.isLoading ? (
          <Skeleton className="rounded-xl" height="223" />
        ) : homeworksData && homeworksData.length ? (
          homeworksData.map((homework) => (
            <QuizBox
              isHomework
              key={homework.id}
              onExecute={(id) => setActioned({ action: 'execute', actionId: id })}
              quiz={homework}
              showActions
              showGrade
              showStatus
            />
          ))
        ) : (
          <NotFoundResult
            subTitle=""
            title={t(isFirstClass ? 'classesPage.noIndependentWork' : 'classesPage.noHomework')}
          />
        )}
      </div>
    );
  }, [filtered.status, homeworksQuery, homeworksData]);

  return (
    <>
      <PageMeta
        openMenuKeys={['homework']}
        selectedMenuKeys={['homework']}
        title={t(isFirstClass ? 'independentWork' : 'homeworks')}
      />

      <div className="overflow-hidden bg-primary-104 px-9 h-[120px] md:h-38 rounded-xl flex items-center justify-between bg-[url('../../../public/images/illustrations/homework-cover.png')] bg-contain bg-right bg-no-repeat">
        <div className="flex flex-col md:gap-1 text-white">
          <span className="font-bold md:text-2xl text-white text-lg">
            {t(isFirstClass ? 'independentWork' : 'homeworks')}
          </span>
        </div>
      </div>

      <Tabs
        onTabChange={(status: IEnumStudentHomeworkStatusKeys) => {
          setFiltered({
            page: 0,
            sort: '-createdDate',
            status,
          });
          status == 'FINISHED' &&
            ReactGA.event({
              action: 'completed_homework',
              category: 'completed_homework',
              label: 'completed_homework',
            });
        }}
        value={
          Array.isArray(filtered.status) || filtered.status == 'ISSUED' ? 'ISSUED' : 'FINISHED'
        }
      >
        <Tabs.List className="mt-8 mb-5 flex gap-4">
          <Tabs.Tab key={IEnumHomeworkStatus.ISSUED} value="ISSUED">
            {getHomeworkStatuses().find((item) => item.value == IEnumHomeworkStatus.ISSUED)?.label}
          </Tabs.Tab>
          <Tabs.Tab key={IEnumHomeworkStatus.FINISHED} value="FINISHED">
            {
              getHomeworkStatuses().find((item) => item.value == IEnumHomeworkStatus.FINISHED)
                ?.label
            }
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="ISSUED">
          <>
            {homeworksTemplate}
            <Pagination
              className="mt-10"
              disabled={homeworksQuery.isFetching || homeworksData?.length === 0}
              onFilter={onFilter}
              size={filtered.size ?? 10}
              total={homeworksQuery.data?.data.totalPages ?? 0}
              value={(Number(filtered.page) ?? 0) + 1}
            />
          </>
        </Tabs.Panel>
        <Tabs.Panel value="FINISHED">
          <>
            {homeworksTemplate}
            <Pagination
              className="mt-10"
              disabled={homeworksQuery.isFetching || homeworksData?.length === 0}
              onFilter={onFilter}
              size={filtered.size ?? 10}
              total={homeworksQuery.data?.data.totalPages ?? 0}
              value={(Number(filtered.page) ?? 0) + 1}
            />
          </>
        </Tabs.Panel>
      </Tabs>

      <ExecuteHomeworkModal
        id={actioned.actionId ?? ''}
        isOpen={actioned.action === 'execute'}
        onClose={clearParams}
      />
    </>
  );
};
