import { FC } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Button, PasswordInput, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { api, IStudentLoginRequest } from '@chess-class/sandbox';

import { PageMeta } from '~/context/PageMetaContext';

const LoginPage: FC = () => {
  const navigate = useNavigate();
  const login = api.user.studentAuth.useStudentLogin();
  const { t } = useTranslation();

  const form = useForm<IStudentLoginRequest>({
    initialValues: api.user.studentAuth.helpers.studentLoginInitial,
    validate: yupResolver(api.user.studentAuth.helpers.studentLoginValidation),
  });

  return (
    <>
      <PageMeta title={t('auth.auth')} />

      <div className="flex flex-col items-center gap-5 mb-8">
        <div className="bg-gray-100 rounded-lg w-16 h-16 p-2.5">
          <img alt="Chess Class logo" className="w-full h-full object-contain" src="/logo.png" />
        </div>
        <span className={clsx('font-bold text-3xl whitespace-nowrap truncate tracking-wide')}>
          Chess <span className="text-primary">Class</span>
        </span>
      </div>

      <form
        className="w-full lg:w-2/3 max-w-sm"
        onSubmit={form.onSubmit(({ email, password }) => {
          login
            .mutateAsync({ email: email.trim(), password: password.trim() })
            .then(() =>
              ReactGA.event({
                action: 'authorization',
                category: 'authorization',
                label: 'authorization',
              }),
            )
            .catch((error) => {
              if (error.response?.data.status == 400) {
                if (error.response.data.message == 'REQUIRED_NEW_PASSWORD') {
                  navigate('/reset-password', {
                    state: { email: email.trim(), password: password.trim() },
                  });
                } else if (error.response.data?.message == 'User is not active') {
                  form.setFieldError('email', t('auth.userNotActive'));
                } else {
                  form.setFieldError('email', t('auth.incorrectLogin'));
                }
              }
            });
        })}
      >
        <TextInput
          label={t('email')}
          name="email"
          placeholder="example@chessclass.ai"
          withAsterisk
          {...form.getInputProps('email')}
        />

        <PasswordInput
          label={t('auth.password')}
          name="password"
          placeholder="******"
          withAsterisk
          {...form.getInputProps('password')}
          className="mt-5"
        />

        <div className="flex flex-col mt-8">
          <Button className="bg-color-[#11122C]" loading={login.isLoading} type="submit">
            {t('auth.enter')}
          </Button>

          <Link className="w-full" to="/reset/email">
            <Button
              className="w-full bg-[#eeeeff] text-[#020246] mt-3 w-full"
              classNames={{ label: 'truncate block' }}
            >
              {t('auth.forgotPassword')}
            </Button>
          </Link>
        </div>
      </form>
    </>
  );
};

export default LoginPage;
