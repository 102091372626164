import { t } from 'i18next';
import { Button, clsx, RingProgress } from '@mantine/core';

import { IClassQuizSessionDetailed, IHomeworkSession } from '@chess-class/sandbox';
import { colors } from '@chess-class/sandbox/consts';
import { IllustrationGemIcon } from '@chess-class/sandbox/icons';

import AttemptsTooltip from '~mHomework/components/atoms/AttemptsTooltip';

export default function QuizStudioFinishBox({
  isLoading,
  onCancel,
  onExitClick,
  quiz,
  solvedRightCount,
}: {
  isLoading: boolean;
  onCancel: () => void;
  onExitClick: () => void;
  quiz: IHomeworkSession | IClassQuizSessionDetailed;
  solvedRightCount: number;
}) {
  return (
    <div className="grid justify-center gap-6 lg:w-2/3 mx-10 md:mx-20 my-10 lg:my-16 self-center flex-grow">
      <div className="bg-white flex flex-col justify-center items-center gap-6 p-4 md:p-14 lg:p-20 lg:px-32 rounded-xl border">
        <span className="text-2xl font-bold">{t('yourResult')}</span>
        <div className="flex flex-col gap-1 rounded-xl border items-center p-6">
          <div className="self-end">
            <AttemptsTooltip />
          </div>
          <div className="flex flex-col gap-4 items-center text-center pt-0 p-4 md:px-6 lg:px-12">
            <div className="flex gap-4 items-center">
              <RingProgress
                rootColor={colors.gray['115']}
                sections={[
                  {
                    color: colors.primary['400'],
                    value: Math.round(
                      (solvedRightCount / (quiz?.studentExercises?.length || 1)) * 100,
                    ),
                  },
                ]}
                size={66}
                thickness={11}
              />
              <span className="text-3xl font-semibold">
                {Math.round((solvedRightCount / (quiz?.studentExercises?.length || 1)) * 100)}%
              </span>
            </div>
            <span className="font-bold text-gray-700">
              {solvedRightCount} / {quiz.studentExercises?.length || 0}{' '}
              <span className="font-medium">{t('correctLessonsCount')}</span>
            </span>
          </div>
        </div>
        <div className="flex items-center gap-1 font-semibold text-base">
          <IllustrationGemIcon className="text-cyan-101" />
          <span>{`${t('studiosPage.attemptsLeft')} ${quiz.totalAttempts - quiz.attempts}`}</span>
        </div>
        <span
          className={clsx(
            'text-medium text-gray-600',
            quiz.totalAttempts - quiz.attempts > 0 && quiz.status != 'FINISHED' ? '' : 'opacity-0',
          )}
        >
          {t('youHaveMoreAttempts')}
        </span>
      </div>
      <div className="flex gap-4">
        <Button className="px-12" disabled={isLoading} onClick={onCancel} variant="outline">
          {t('goBack')}
        </Button>
        <Button className="px-12" loading={isLoading} onClick={onExitClick} variant="primary">
          {t('exit')}
        </Button>
      </div>
    </div>
  );
}
