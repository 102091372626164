import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { clsx, Menu } from '@mantine/core';

import {
  api,
  IClassQuizSession,
  IClassQuizSessionDetailed,
  IHomeworkSession,
} from '@chess-class/sandbox';
import { StatusTag } from '@chess-class/sandbox/components';
import {
  ArrowDotDashDashIcon,
  Assignment3Icon,
  BookOpenIcon,
  ClockIcon,
  GuidelineChapterExercisesIcon,
  IllustrationBookHomeworkIcon,
} from '@chess-class/sandbox/icons';
import { dateFormat } from '@chess-class/sandbox/utils';

import { StudentContext } from '~/context/StudentContext';
import { ChaptersPopover } from '~/modules/classQuiz/components/molecules/ChaptersPopover';

import QuizBoxBottomRow from '~mHomework/components/atoms/QuizBoxBottomRow';

type QuizBoxProps = {
  isHomework?: boolean;
  onExecute?: (id: string) => void;
  quiz: IHomeworkSession | IClassQuizSessionDetailed | IClassQuizSession;
  showActions?: boolean;
  showChapters?: boolean;
  showGrade?: boolean;
  showStatus?: boolean;
};

export const QuizBox: FC<QuizBoxProps> = ({
  isHomework,
  onExecute,
  quiz,
  showActions,
  showChapters,
  showGrade,
  showStatus,
}) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { school } = useContext(StudentContext);
  const chaptersQuery = api.guideline.guidelineChapters.useGuidelineChapters({
    ids:
      isHomework || !showChapters
        ? []
        : (quiz as IClassQuizSessionDetailed)?.classQuiz?.chapterIds || [],
    size: 9999,
    sort: 'priority',
  });

  const sortedChapters =
    school?.educationFormat == 'GENERAL'
      ? chaptersQuery.data?.data.content
      : chaptersQuery.data?.data.content?.sort((a, b) => a.numId - b.numId);

  return (
    <div
      className={clsx(
        'rounded-xl bg-white flex flex-col md:gap-6 gap-2 w-full self-start',
        showStatus ? 'border shadow-sm md:p-6 p-4' : '',
      )}
    >
      <div className="flex justify-between w-full">
        <div className="flex md:gap-8 gap-4 flex-col md:flex-row min-w-0 w-full">
          <div className="flex justify-between gap-4">
            {isHomework ? (
              <div className="flex h-24 w-24 shrink-0  justify-center items-end rounded-lg place-self-start bg-primary-800 overflow-hidden">
                <IllustrationBookHomeworkIcon className="h-22 w-16 -mb-1" />
              </div>
            ) : (
              <div className="flex bg-primary-110 h-24 w-24  items-center justify-center rounded-xl">
                <Assignment3Icon className="w-14 h-14 rounded" />
              </div>
            )}

            {showStatus && (
              <div className="md:hidden">
                <StatusTag status={quiz?.status ?? 'DRAFT'} />
              </div>
            )}
          </div>

          <div className="py-1 min-w-0 flex flex-col w-full">
            <div className="flex gap-1 justify-between">
              <h4 className="font-bold md:text-2xl text-base text-primary-900 mb-3 ">
                {isHomework
                  ? `${t('homework')} #${(quiz as IHomeworkSession).orderByGroup} - ${t(
                      'chapter',
                    )} ${
                      school?.educationFormat == 'GENERAL'
                        ? (quiz as IHomeworkSession).chapter.priority
                        : (quiz as IHomeworkSession).chapter.numId
                    }`
                  : `${t('assignment')} #${
                      (quiz as IClassQuizSessionDetailed).classQuiz?.orderByClass
                    }`}
              </h4>
              <div className="hidden md:block">
                <StatusTag status={quiz?.status ?? 'DRAFT'} />
              </div>
            </div>
            <div className="flex flex-col gap-2 text-gray-400">
              <div className="flex md:gap-8 gap-2 sm:gap-4 whitespace-nowrap font-medium">
                <span className="flex items-center gap-1 sm:gap-2">
                  <GuidelineChapterExercisesIcon />
                  <span>
                    {quiz.studentExercises?.length || 0}{' '}
                    {t('problem', { count: quiz.studentExercises?.length || 0 })}
                  </span>
                </span>
                <span className="flex items-center gap-1 sm:gap-2 min-w-0">
                  <BookOpenIcon />

                  {isHomework ? (
                    <Link
                      className="text-primary-400 font-semibold truncate"
                      target="_blank"
                      to={`/lessons/${(quiz as IHomeworkSession).chapter.id}`}
                    >
                      {(quiz as IHomeworkSession).chapter.translations?.[language]?.title || '-'}
                    </Link>
                  ) : (
                    <div className="flex items-center gap-1 truncate min-w-0">
                      <span className="hidden md:block">
                        {t('assignmentsPage.assignmentConsistOf')}
                      </span>
                      <Menu
                        closeDelay={400}
                        openDelay={100}
                        trigger="click"
                        variant="actions"
                        withArrow
                      >
                        <Menu.Target>
                          <span className="text-primary cursor-pointer">
                            {(quiz as IClassQuizSessionDetailed).classQuiz.chapterIds.length}{' '}
                            {t('chapter', {
                              count: (quiz as IClassQuizSessionDetailed).classQuiz.chapterIds
                                .length,
                            }).toLowerCase()}
                          </span>
                        </Menu.Target>
                        <ChaptersPopover
                          chapterIds={(quiz as IClassQuizSessionDetailed).classQuiz.chapterIds}
                        />
                      </Menu>
                    </div>
                  )}
                </span>
              </div>
              <div className={clsx('flex flex-row flex-wrap gap-2')}>
                <span className="flex flex-wrap items-center gap-1 whitespace-nowrap">
                  <ClockIcon className="w-4 h-4" />
                  {dateFormat(quiz.startAt, 'DD.MM.YYYY HH:mm', true, 0)}
                </span>
                <ArrowDotDashDashIcon className="w-11" />
                <span className="flex flex-wrap items-center gap-1 whitespace-nowrap">
                  <ClockIcon className="w-4 h-4" />
                  {dateFormat(quiz.finishAt, 'DD.MM.YYYY HH:mm', true, 0)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showChapters && !isHomework && (
        <div className="flex flex-col gap-6 mt-4 md:mt-0">
          {sortedChapters?.map((chap) => (
            <Link
              className="flex items-center gap-4 text-base text-gray-400"
              key={chap.id}
              target="_blank"
              to={`/lessons/${chap.id}`}
            >
              <span className="text-primary font-medium">
                {t('chapter')} {school?.educationFormat == 'GENERAL' ? chap.priority : chap.numId}
              </span>{' '}
              {chap.translations?.[i18n.language]?.title}
            </Link>
          ))}
        </div>
      )}

      {showStatus && (
        <QuizBoxBottomRow
          isHomework={isHomework}
          onExecute={onExecute}
          quiz={quiz}
          showActions={showActions}
          showGrade={showGrade}
        />
      )}
    </div>
  );
};
