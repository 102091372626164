import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '@mantine/core';

import { PageMeta } from '~/context/PageMetaContext';

const ConfirmEmailPage: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const email = searchParams.get('email');

  return (
    <>
      <PageMeta title={t('auth.recoverPassword')} />
      <div>
        <h2 className="font-bold mb-6 text-center text-3xl sm:text-4xl text-gray-900 truncate">
          {t('auth.pleaseCheckEmail')}
        </h2>
        <p className="text-center">
          {t('auth.pleaseCheckEmail')}
          <br /> ”{email}. {t('auth.sendConfirmation')}
        </p>
        <Button
          className="bg-color-[#11122C] w-full mt-8"
          onClick={() => navigate('/reset/email')}
          type="submit"
        >
          {t('auth.changeEmail')}
        </Button>
      </div>
    </>
  );
};

export default ConfirmEmailPage;
