import { useTranslation } from 'react-i18next';
import { clsx } from '@mantine/core';

import { IExercise } from '@chess-class/sandbox';
import { TestAnswersBox } from '@chess-class/sandbox/components';
import { IllustrationRookYellowIcon } from '@chess-class/sandbox/icons';

export default function StudioExerciseTest({
  defaultChecked,
  disabled,
  exercise,
  onAnswer,
}: {
  defaultChecked?: number;
  disabled: boolean;
  exercise: IExercise;
  onAnswer: (passed: boolean, answer: number) => void;
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  if (!exercise?.test) {
    return <div></div>;
  }

  return (
    <>
      <div
        className={clsx(
          'w-[calc(min(90vw,90vh))] h-auto flex flex-col lg:w-[calc(min(66vw,66vh))] max-w-full md:max-w-[1096px] min-h-full',
        )}
      >
        <div className="p-6 rounded-lg bg-white flex flex-col gap-6 min-w-0 ">
          <img className="max-h-[300px] object-contain" src={exercise.test.imageUrl} />
          <span className="font-bold text-base text-[#202124]">
            {exercise.test.translations?.[language]?.question}
          </span>
          <TestAnswersBox
            defaultCheckedAnswer={defaultChecked}
            exercise={exercise}
            isView={disabled}
            onAnswer={(correct, index) => {
              onAnswer(correct, index);
            }}
          />
        </div>
      </div>
      <div className="hidden lg:block">
        <div className="p-6 rounded-lg flex flex-col gap-8 bg-white items-center lg:w-80">
          <div className="p-8 flex flex-col gap-2 items-center">
            <IllustrationRookYellowIcon className="w-44 h-44" />
            <span className="font-semibold">{t('studiosPage.chooseAnswer')}</span>
          </div>
        </div>
      </div>
    </>
  );
}
