import { Skeleton } from '@mantine/core';

export default function InputSkeleton({ label }: { label?: boolean }) {
  return (
    <div className="flex flex-col gap-2">
      {label && <Skeleton className="h-4 w-20" />}
      <Skeleton className="h-12 w-full" radius="md" />
    </div>
  );
}
