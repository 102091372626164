import type { FC } from 'react';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { RingProgress, Skeleton } from '@mantine/core';

import { api, IStudentHomeworkRequest } from '@chess-class/sandbox';
import { GradeBox, NotFoundResult } from '@chess-class/sandbox/components';
import { colors } from '@chess-class/sandbox/consts';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { PageMeta } from '~/context/PageMetaContext';
import { StudentContext, useStudentContext } from '~/context/StudentContext';
import ExerciseDetailsModal from '~/modules/lessons/components/molecules/ExerciseDetailsModal';

import AttemptsTooltip from '~mHomework/components/atoms/AttemptsTooltip';
import HomeworkExerciseCardTable from '~mHomework/components/organisms/HomeworkExerciseCardTable';
import { QuizBox } from '~mHomework/components/organisms/QuizBox';

import { homeworkFilters } from '../../consts/filters/homeworkFilters';
import {
  homeworkPageActions,
  IHomeworkPageActions,
} from '../../consts/pageActions/homeworkPageActions';
import QuizTestDetailsModal from '../molecules/QuizTestDetailsModal';

type HomeworkDetailsPageProps = {
  isHomework?: boolean;
};

// FIXME: add translation
export const QuizDetailsPage: FC<HomeworkDetailsPageProps> = ({ isHomework }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { isFirstClass } = useStudentContext();

  const { actioned, clearParams, setActioned, setPartialActioned } = useUrlFilteredActioned<
    IStudentHomeworkRequest,
    IHomeworkPageActions
  >(homeworkFilters, homeworkPageActions);

  const quizQuery = isHomework
    ? api.education.homework.useStudentHomework({ id })
    : api.education.classQuiz.useStudentClassQuiz({ id });

  const quiz = quizQuery.data?.data;

  const { school } = useContext(StudentContext);

  const exerciseQuery = api.content.exercises.useExercise({ id: actioned.actionId });
  const exercise = exerciseQuery.data?.data;
  const exerciseIndex =
    quiz?.studentExercises.findIndex((ex) => ex.exerciseId == actioned.actionId) ?? 0;

  useEffect(() => {
    if (quiz?.status == 'ISSUED') {
      navigate('/homework');
    }
  }, [quiz]);

  return (
    <div className="flex flex-col md:gap-5 ">
      <PageMeta
        goBack={{ i18Title: 'back', url: isHomework ? 'homework' : 'classQuizzes' }}
        selectedMenuKeys={[isHomework ? 'homework' : 'classQuizzes']}
        title={!isHomework ? t('assignment') : isFirstClass ? t('independentWork') : t('homework')}
      />

      {quizQuery.isLoading ? (
        <>
          <Skeleton className="rounded-xl h-56" />
          <Skeleton className="w-24 h-8" />
          <Skeleton className="rounded-xl h-56" />
        </>
      ) : !quiz ? (
        <NotFoundResult />
      ) : (
        <>
          <QuizBox isHomework={isHomework} quiz={quiz} showChapters showStatus />

          <div className="text-lg md:text-2xl font-bold my-4 md:mb-0">
            {' '}
            {school?.educationFormat == 'GENERAL' ? t('grade') : t('result')}
          </div>

          <div className="rounded-2xl flex md:flex-row flex-col gap-12 bg-white md:px-10 md:py-13 px-4 py-6">
            <div className="rounded-lg bg-primary-108 py-5 px-7 md:min-w-[366px]">
              <div className="flex justify-between">
                <span className="text-white text-base font-semibold">{t('executionReport')}:</span>
                <AttemptsTooltip size="lg" />
              </div>

              <div className="flex items-center justify-center gap-5 my-7">
                <RingProgress
                  sections={[
                    {
                      color: colors.primary['400'],
                      value: Math.round(
                        (quiz.totalSolved / (quiz?.studentExercises?.length || 1)) * 100,
                      ),
                    },
                  ]}
                  size={80}
                  thickness={13}
                />
                <span className="text-4xl text-primary-900 font-semibold">
                  {Math.round((quiz.totalSolved / (quiz?.studentExercises?.length || 1)) * 100)}%
                </span>
              </div>

              <span className="text-white font-medium">
                {t('homeworksPage.correctTasksCount', {
                  count: quiz.totalSolved,
                  total: quiz?.studentExercises?.length || 0,
                })}
              </span>
            </div>
            {school?.educationFormat == 'GENERAL' && (
              <div className="rounded-lg flex flex-col justify-between bg-primary-50 py-5 px-7 md:min-w-[366px]">
                <span className="text-gray-600 text-base font-semibold">
                  {school?.educationFormat == 'GENERAL' ? t('grade') : t('result')}
                </span>

                <div className="flex items-center justify-center md:py-0 py-4">
                  <GradeBox
                    border
                    className="w-20 h-20 text-3xl rounded-3xl"
                    grade={
                      quiz.evaluation ||
                      (quiz.totalSolved /
                        (quiz?.studentExercises?.length || quiz.totalAttempts / 2 || 1)) *
                        100
                    }
                    gradeType={quiz.gradeType}
                  />
                </div>

                <div className="font-medium">
                  <span className="text-primary-400 font-bold">
                    {Math.max(
                      Math.round(
                        ((quiz.totalSolved / (quiz?.studentExercises?.length || 1)) * 100 || 1) /
                          (quiz?.gradeType == 'GRADE_TYPE_10' ? 10 : 20),
                      ),
                      2,
                    )}{' '}
                  </span>
                  {t('homeworksPage.possible', {
                    count: quiz?.gradeType == 'GRADE_TYPE_10' ? 10 : 5,
                  })}
                </div>
              </div>
            )}
          </div>

          {!!quiz?.studentExercises && (
            <HomeworkExerciseCardTable
              setActioned={setActioned}
              studentExercises={quiz?.studentExercises}
            />
          )}
          {exercise && (
            <ExerciseDetailsModal
              exercise={exercise}
              isBackButtonDisabled={exerciseIndex == 0}
              isNextButtonDisabled={exerciseIndex >= quiz.studentExercises.length - 1}
              isOpen={actioned.action === 'view' && actioned.actionType === 'exercise'}
              isView
              onBackButtonClick={() =>
                setPartialActioned({
                  action: 'view',
                  actionId: `${quiz.studentExercises[exerciseIndex - 1].exerciseId}`,
                  actionType: 'exercise',
                })
              }
              onCancel={clearParams}
              onNextButtonClick={() => {
                setPartialActioned({
                  action: 'view',
                  actionId: `${quiz?.studentExercises[exerciseIndex + 1].exerciseId}`,
                  actionType: 'exercise',
                });
              }}
              status={
                quiz?.studentExercises?.find((ex) => ex.exerciseId == actioned.actionId)?.status
              }
            />
          )}

          <QuizTestDetailsModal
            answerChoice={
              quiz?.studentExercises?.find((ex) => ex.exerciseId == actioned.actionId)?.answerChoice
            }
            exerciseId={actioned.actionId ?? ''}
            isBackButtonDisabled={exerciseIndex == 0}
            isNextButtonDisabled={exerciseIndex >= quiz.studentExercises.length - 1}
            isOpen={actioned.action === 'view' && actioned.actionType === 'test'}
            isView
            onBackButtonClick={() =>
              setPartialActioned({
                action: 'view',
                actionId: `${quiz.studentExercises[exerciseIndex - 1].exerciseId}`,
                actionType: 'test',
              })
            }
            onCancel={clearParams}
            onNextButtonClick={() => {
              setPartialActioned({
                action: 'view',
                actionId: `${quiz?.studentExercises[exerciseIndex + 1].exerciseId}`,
                actionType: 'test',
              });
            }}
            status={
              quiz?.studentExercises?.find((ex) => ex.exerciseId == actioned.actionId)?.status
            }
          />
        </>
      )}
    </div>
  );
};
