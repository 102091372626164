import { t } from 'i18next';
import { clsx } from '@mantine/core';

import { IEnumClassQuizStudentExerciseStatusKeys } from '@chess-class/sandbox';
import { Student2Icon } from '@chess-class/sandbox/icons';

export default function HomeworkStatusBox({
  status,
}: {
  status: IEnumClassQuizStudentExerciseStatusKeys;
}) {
  return (
    <div className="flex items-center gap-4 font-medium text-base text-primary-109">
      <div
        className={clsx(
          'md:w-10 md:h-10 h-8 w-8 flex items-center justify-center shrink-0 rounded-lg border',
          status == 'SOLVED'
            ? 'bg-green-102 text-green-103 border-green-103'
            : status == 'FAILED'
              ? 'bg-red-102 text-red-103 border-red-103'
              : status == 'NA'
                ? 'bg-orange-101 text-orange-102 border-orange-102'
                : 'bg-gray-100 text-gray-300 border-gray-300',
        )}
      >
        <Student2Icon />
      </div>
      <div className="md:block hidden">{t(`exerciseStatus.${status}`)}</div>
    </div>
  );
}
