import { useTranslation } from 'react-i18next';
import { Table } from '@mantine/core';

import { IHomeworkStudentExercise } from '@chess-class/sandbox';

import HomeworkExerciseCardRow from '~mHomework/components/atoms/HomeworkExerciseCardRow';

import { ILessonPageActions } from '~mLessons/consts/pageActions/ILessonPageActions';

export default function HomeworkExerciseCardTable({
  setActioned,
  studentExercises,
}: {
  setActioned: (actioned: Partial<ILessonPageActions>) => void;
  studentExercises: IHomeworkStudentExercise[];
}) {
  const { t } = useTranslation();

  const rows = studentExercises.map((exercise) => (
    <HomeworkExerciseCardRow
      homeworkExercise={exercise}
      key={exercise.exerciseId}
      setActioned={setActioned}
    />
  ));

  return (
    <Table className="bg-white card-table rounded-xl p-6 mb-5 md:mt-0 mt-4" highlightOnHover>
      <thead>
        <tr>
          <th>{t('homeworksPage.tasks')}</th>
          <th className="md:block hidden">{t('statuses.FINISHED')}</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}
