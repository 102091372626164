import { useContext } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from '@mantine/core';

import { IClassChapter, IGuidelineChapterTiny } from '@chess-class/sandbox';
import {
  ClockIcon,
  GuidelineChapterExercisesIcon,
  IllustrationCrownIcon,
} from '@chess-class/sandbox/icons';
import { dateFormat } from '@chess-class/sandbox/utils';

import { StudentContext } from '~/context/StudentContext';

export default function ChapterBox({
  chapter,
}: {
  chapter: Partial<IClassChapter> & IGuidelineChapterTiny;
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const { school } = useContext(StudentContext);

  return (
    <div className="flex-col lg:flex-row items-start rounded-xl flex gap-8 lg:py-6 lg:px-8 p-4 shadow-[0_4px_0px_0] shadow-gray-200 bg-white lg:items-center">
      <div className="border-[7px] p-[5px] rounded-full">
        <div className="w-22 h-22 bg-primary-107 rounded-full py-4.5 pr-2 pl-3 relative">
          <img className="h-full w-full object-contain" src="/images/illustrations/book-open.png" />
          <div className="absolute -right-4 -bottom-2">
            <IllustrationCrownIcon className="w-9 h-9 text-yellow-500" />
            <span className="absolute left-1/2 -translate-x-1/2 bottom-[9px] font-bold">
              {school?.educationFormat == 'GENERAL' ? chapter.priority : chapter.numId}
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <span className="text-lg font-bold mb-4 lg:mb-0">
          {t('chapter')} {school?.educationFormat == 'GENERAL' ? chapter.priority : chapter.numId}:{' '}
          {chapter.translations?.[language]?.title}
        </span>
        <div className="flex items-center gap-1 text-gray-400 whitespace-nowrap">
          <GuidelineChapterExercisesIcon className="w-4 h-4" />
          {chapter.exercisesCount || 0} {t('problem', { count: chapter.exercisesCount || 0 })}
        </div>
        <div className="flex items-center gap-1 text-gray-400 whitespace-nowrap">
          <ClockIcon className="w-4 h-4" />
          {t('givenDate')}: {dateFormat(chapter.givenDate || '', 'DD.MM.YYYY H:mm', true)}
        </div>
      </div>

      <Link
        className="ml-auto w-full lg:w-auto"
        onClick={() =>
          ReactGA.event({
            action: 'view_lesson',
            category: 'view_lesson',
            label: 'view_lesson',
          })
        }
        to={`/lessons/${chapter.chapterId}`}
      >
        <Button className="px-14 w-full" variant="primary">
          {t('more')}
        </Button>
      </Link>
    </div>
  );
}
