import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import moment from 'moment';
import { clsx, Input, Pagination, UnstyledButton } from '@mantine/core';
import { DateInput } from '@mantine/dates';

import { api, IClassChapter, IGuidelineChapterTiny } from '@chess-class/sandbox';
import { NotFoundResult } from '@chess-class/sandbox/components';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { CalendarIcon, IllustrationHorseOrangeIcon, SearchIcon } from '@chess-class/sandbox/icons';
import { dateFormat } from '@chess-class/sandbox/utils';

import { PageMeta } from '~/context/PageMetaContext';
import { useStudentContext } from '~/context/StudentContext';

import 'moment/locale/ru';
import ChapterSkeleton from '~mLessons/components/atoms/ChapterSkeleton';
import ChapterBox from '~mLessons/components/molecules/ChapterBox';
import { LessonsFilters, lessonsFilters } from '~mLessons/consts/filters/lessonsFilters';

moment.locale('ru');

export default function LessonsPage() {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { school, student } = useStudentContext();

  useEffect(() => {
    moment.locale(language);
  }, [language]);

  const { filtered, onFilter } = useUrlFilteredActioned<LessonsFilters>(lessonsFilters);

  const classChaptersQuery = api.education.classChapters.useClassChapters({
    groupId: student?.groupId,
    status: 'GIVEN',
  });
  const classChapters = classChaptersQuery.data?.data;

  const guidelineChaptersQuery = api.guideline.guidelineChapters.useGuidelineChapters({
    enabled: !!classChapters?.length,
    ids: classChapters?.map((chap) => chap.chapterId),
    page: 0,
    size: 1024,
    sort: '-priority',
  });
  const guidelineChapters = guidelineChaptersQuery.data?.data.content;

  const chapters: (IGuidelineChapterTiny & Partial<IClassChapter>)[] | undefined = useMemo(() => {
    return (
      guidelineChapters
        ?.map((guideChapter) => ({
          ...guideChapter,
          ...classChapters?.find((chapter) => chapter.chapterId == guideChapter.id),
        }))
        .filter((chapter) => {
          let isGood = !!chapter.chapterId;
          if (filtered.search && isGood) {
            isGood =
              !!chapter.translations?.[language]?.title
                ?.toLowerCase()
                .includes(filtered.search.toLowerCase()) ||
              !!chapter.translations?.[language]?.description
                ?.toLowerCase()
                .includes(filtered.search.toLowerCase());
          }
          if (filtered.date && isGood) {
            isGood = dateFormat(chapter.givenDate || '', 'DD.MM.YYYY') == filtered.date;
          }
          return isGood;
        }) || []
    );
  }, [guidelineChapters, classChapters, filtered]);

  const pagedChapters = useMemo(() => {
    const page =
      typeof filtered.page == 'string' ? Number.parseInt(filtered.page) : filtered.page || 1;
    const size = filtered.size || 10;

    return chapters?.slice(page * size - size, page * size);
  }, [chapters]);

  const sortedChapters =
    school?.educationFormat == 'GENERAL'
      ? pagedChapters?.sort((a, b) => a.numId - b.numId).slice(0, 3)
      : pagedChapters?.sort((a, b) => a.numId - b.numId);

  return (
    <div className="flex flex-col gap-4 lg:gap-10">
      <PageMeta selectedMenuKeys={['lessons']} title={t('lessons')} />

      <div className="bg-primary-106 px-9 h-38 rounded-xl flex items-center justify-between bg-[url('../../../public/images/illustrations/lessons-clouds-cover.png')] bg-cover bg-no-repeat">
        <div className="flex flex-col gap-1 text-white">
          <span className="text-2xl font-bold">{t('lessons')}</span>
          <span className="text-sm">{t('lessonsPage.topicsMessage')}</span>
        </div>
        <div className="h-full flex z-10 gap-8">
          <img
            className="h-5/6 mt-auto object-contain"
            src="/images/illustrations/pawn-good-purple.png"
          />
          <div
            className={clsx(
              'p-4 my-8 bg-white rounded-xl whitespace-nowrap relative items-center',
              'before:absolute before:w-8 before:h-8 before:content-[""] before:rounded-lg',
              'before:-left-2 before:top-[55%] before:-translate-y-1/2 before:rotate-45',
              'before:bg-white before:-z-10 hidden md:flex',
            )}
          >
            <span className="font-bold text-base">
              <span dangerouslySetInnerHTML={{ __html: t('lessonsPage.welcomeToChess') }}></span>
              <span className="text-primary" />
            </span>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex justify-start md:justify-end gap-4">
          <Input
            classNames={{ input: 'h-10.5 bg-white', wrapper: 'h-10.5' }}
            onChange={(event) => onFilter({ page: 0, search: event.target.value })}
            placeholder={t('search')}
            rightSection={<SearchIcon className="text-gray-400" />}
            value={filtered.search || ''}
          />
          <DateInput
            classNames={{ input: 'h-10.5 bg-white', wrapper: 'h-10.5' }}
            clearable
            locale={language}
            onChange={(date) =>
              onFilter({ date: date ? dayjs(date).format('DD.MM.YYYY') : undefined, page: 0 })
            }
            placeholder="DD.MM.YYYY"
            rightSection={filtered.date ? undefined : <CalendarIcon className="text-gray-400" />}
            value={
              filtered.date && moment(filtered.date, 'DD.MM.YYYY').isValid()
                ? moment(filtered.date, 'DD.MM.YYYY').toDate()
                : null
            }
            valueFormat="DD.MM.YYYY"
          />
        </div>

        {classChaptersQuery.isLoading ||
        (!!classChapters?.length && guidelineChaptersQuery.isLoading) ? (
          <>
            <ChapterSkeleton />
            <ChapterSkeleton />
          </>
        ) : !pagedChapters?.length ? (
          <NotFoundResult
            Icon={IllustrationHorseOrangeIcon}
            subTitle={classChapters?.length ? t('searchHelpText') : t('notFoundResult.noChapters')}
            title={t('notFoundResult.noLessons')}
          />
        ) : (
          sortedChapters.map((chapter) => <ChapterBox chapter={chapter} key={chapter.id} />)
        )}
      </div>

      <div className="flex justify-between gap-4">
        <Pagination
          classNames={{
            control:
              'border-gray-200 data-[active=true]:bg-white data-[active=true]:border-primary data-[active=true]:text-primary',
          }}
          onChange={(page) => onFilter({ page })}
          total={Math.ceil((chapters?.length || 1) / (filtered.size || 10))}
          value={
            typeof filtered.page == 'string' ? Number.parseInt(filtered.page) : filtered.page || 1
          }
        />
        <div className=" gap-3 hidden md:flex">
          {[10, 20, 50].map((size) => (
            <UnstyledButton
              className={clsx(
                'p-2',
                filtered.size == size ? 'border border-solid bg-white border-gray-300 rounded' : '',
              )}
              key={`size-${size}`}
              onClick={() => onFilter({ size })}
            >
              {size}
            </UnstyledButton>
          ))}
        </div>
      </div>
    </div>
  );
}
