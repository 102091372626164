import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Modal, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import { api, ReviewContentTypeEnumKeys } from '@chess-class/sandbox';
import {
  IllustrationStudentRate1Icon,
  IllustrationStudentRate2Icon,
  IllustrationStudentRate3Icon,
  IllustrationStudentRate4Icon,
  IllustrationStudentRate5Icon,
  IllustrationStudentRateDisabled1Icon,
  IllustrationStudentRateDisabled2Icon,
  IllustrationStudentRateDisabled3Icon,
  IllustrationStudentRateDisabled4Icon,
  IllustrationStudentRateDisabled5Icon,
} from '@chess-class/sandbox/icons';
import { notify } from '@chess-class/sandbox/utils';

import { StudentContext } from '~/context/StudentContext';

type ReviewContentModalProps = {
  contentId: string;
  contentType: ReviewContentTypeEnumKeys;
  isOpen: boolean;
  onClose: () => void;
  onFinish?: () => void;
  title: string;
};

const icons = [
  {
    disabledIcon: <IllustrationStudentRateDisabled1Icon className="w-16 h-16 md:h-20 md:w-20" />,
    icon: <IllustrationStudentRate1Icon className="w-16 h-16 md:h-20 md:w-20" />,
  },
  {
    disabledIcon: <IllustrationStudentRateDisabled2Icon className="w-16 h-16 md:h-20 md:w-20" />,
    icon: <IllustrationStudentRate2Icon className="w-16 h-16 md:h-20 md:w-20" />,
  },
  {
    disabledIcon: <IllustrationStudentRateDisabled3Icon className="w-16 h-16 md:h-20 md:w-20" />,
    icon: <IllustrationStudentRate3Icon className="w-16 h-16 md:h-20 md:w-20" />,
  },
  {
    disabledIcon: <IllustrationStudentRateDisabled4Icon className="w-16 h-16 md:h-20 md:w-20" />,
    icon: <IllustrationStudentRate4Icon className="w-16 h-16 md:h-20 md:w-20" />,
  },
  {
    disabledIcon: <IllustrationStudentRateDisabled5Icon className="w-16 h-16 md:h-20 md:w-20" />,
    icon: <IllustrationStudentRate5Icon className="w-16 h-16  md:h-20  md:w-20" />,
  },
];

export const ReviewContentModal: FC<ReviewContentModalProps> = ({
  contentId,
  contentType,
  isOpen,
  onClose,
  onFinish,
  title,
}) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { school, student } = useContext(StudentContext);
  const [hoveredIndex, setHoveredIndex] = useState<null | number>(null);
  const [rate, setRate] = useState(0);
  const [step, setStep] = useState(1);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const form = useForm({});

  const postReview = api.reviews.reviews.useMutateReview();

  return (
    <Modal
      centered
      onClose={onClose}
      opened={(isOpen && school?.educationFormat == 'PRIVATE') ?? false}
      size={570}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          const actionData = {
            contentId: contentId,
            contentLanguage: language,
            contentType: contentType,
            educationFormat: 'PRIVATE',
            schoolId: school?.id ?? '',
            schoolName: school?.name ?? '',
            text: values.text as string,
            userFullName: `${student?.lastName} ${student?.firstName} ${student?.middleName}`,
            userId: student?.id ?? '',
            userRole: 'STUDENT',
            value: rate,
          };
          postReview
            .mutateAsync(actionData)
            .then((data) => {
              onFinish && onFinish();
              if (data.status == 201 || data.status == 200) {
                notify('success', t('messages.successReviewPost'));
              }
              onClose();
            })
            .catch(() => {
              notify('error', t('messages.errorPostReview'));
            });
        })}
      >
        <div className="mb-13 mx-auto mt-5 text-center font-semibold text-gray-600 text-base flex flex-col gap-4">
          {step == 1 && (
            <>
              <span className="text-[24px] font-bold"> {title}</span>
              <p className="text-[24px]">{t('review.thisWillHelpUpToImprove')}</p>
              <div className="flex flex-row items-center justify-center h-[130px] px-6 gap-3">
                {new Array(5).fill(null).map((element, index) => (
                  <div
                    className="w-[62px] h-[62px] mr-1 ml-1 cursor-pointer"
                    key={index}
                    onClick={() => setRate(index + 1)}
                    onMouseEnter={() => handleMouseEnter(index + 1)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {(hoveredIndex !== null && index + 1 <= hoveredIndex) || index + 1 <= rate
                      ? icons[index].icon
                      : icons[index].disabledIcon}
                  </div>
                ))}
              </div>
            </>
          )}
          {step == 2 && (
            <>
              {rate && rate <= 2 ? (
                <>
                  <span className="text-[24px] font-bold">{t('review.whatYouDidntLike')}</span>
                  <br /> <p className="text-[16px]">{t('review.thisWillHelpUpToImprove')}</p>
                </>
              ) : (
                <span className="text-[24px] font-bold">{t('review.whatDidYouLike')}</span>
              )}
              <div className="p-6">
                <Textarea
                  {...form.getInputProps('text')}
                  autosize
                  className="text-[14px] font-normal"
                  minRows={5}
                  placeholder={t('review.tellUsMoreDetails')}
                  required={rate <= 2}
                />
              </div>
            </>
          )}
        </div>

        <Divider className="mb-8 -mx-6" />
        <div className="flex items-center justify-center ">
          {step == 1 && (
            <Button className="w-[50%]" onClick={() => setStep(step + 1)} variant="primary">
              {t('next')}
            </Button>
          )}
          {step == 2 && (
            <div className="grid grid-cols-2 gap-6">
              <Button className="w-full" onClick={() => setStep(1)} variant="outline">
                {t('back')}
              </Button>
              <Button
                className="w-full"
                loading={postReview.isLoading}
                type="submit"
                variant="primary"
              >
                {t('auth.send')}
              </Button>
            </div>
          )}
        </div>
      </form>
    </Modal>
  );
};
