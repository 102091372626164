import { useTranslation } from 'react-i18next';
import { Skeleton, Text } from '@mantine/core';

import { api, IHomeworkStudentExercise } from '@chess-class/sandbox';

import HomeworkStatusBox from '~mHomework/components/atoms/HomeworkStatusBox';

import { ILessonPageActions } from '~mLessons/consts/pageActions/ILessonPageActions';

export default function HomeworkExerciseCardRow({
  homeworkExercise,
  setActioned,
}: {
  homeworkExercise: IHomeworkStudentExercise;
  setActioned: (actioned: Partial<ILessonPageActions>) => void;
}) {
  const exerciseQuery = api.content.exercises.useExercise({ id: homeworkExercise.exerciseId });
  const exercise = exerciseQuery.data?.data;
  const { t } = useTranslation();

  if (exerciseQuery.isLoading) {
    return (
      <tr>
        <td>
          <Skeleton className="w-4 h-4" />
        </td>
        <td></td>
      </tr>
    );
  }
  return (
    <tr
      className="cursor-pointer"
      key={homeworkExercise.exerciseId}
      onClick={() => {
        if (exercise) {
          setActioned({
            action: 'view',
            actionId: homeworkExercise.exerciseId,
            actionType: exercise.type == 'TEST' ? 'test' : 'exercise',
          });
        }
      }}
    >
      <td className="flex flex-row items-center bg-transparent px-4 py-2 md:px-6 md:py-5">
        <img
          alt="Test"
          className="md:w-12 md:h-12 h-9 w-9 mr-6 object-contain"
          src={`/images/illustrations/${exercise?.type == 'TEST' ? 'test-img' : 'problem-img'}.png`}
        />
        <Text className="text-sm md:text-base text-[#11122C] font-bold">
          {exercise?.type == 'TEST' ? t('test') : t('exercise')} #{exercise?.numId || exercise?.id}
        </Text>
      </td>

      <td className="bg-transparent">
        <HomeworkStatusBox status={homeworkExercise.status} />
      </td>
    </tr>
  );
}
