import React, { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Navbar, NavLink, Stack } from '@mantine/core';

import { api } from '@chess-class/sandbox';
import { IllustrationLogoIcon } from '@chess-class/sandbox/icons';

import { SidebarInterface, sidebars } from '~/consts/sidebars';
import { PageMetaContext } from '~/context/PageMetaContext';

function ChessNavLinkWrapper({
  children,
  isLink,
  link,
}: {
  children: JSX.Element;
  isLink: boolean;
  link: string;
}) {
  if (isLink) {
    return <Link to={link}>{children}</Link>;
  }
  return children;
}

export function ChessNavLink({
  item,
  openMenuKeys,
  parentKey,
  parentLink,
  selectedMenuKeys,
}: {
  item: SidebarInterface;
  openMenuKeys?: string[];
  parentKey?: string;
  parentLink?: string;
  selectedMenuKeys?: string[];
}) {
  const currentKey = parentKey ? `${parentKey}-${item.key}` : item.key;
  const currentLink = parentLink ? `${parentLink}/${item.link}` : item.link;
  const isActive = selectedMenuKeys?.includes(currentKey);
  const isOpen = openMenuKeys?.includes(currentKey);
  const Icon = item.icon;

  return (
    <ChessNavLinkWrapper isLink={!item.children?.length} link={currentLink || ''}>
      <NavLink
        active={isActive}
        childrenOffset={0}
        className={isActive ? 'active' : isOpen ? 'open' : ''}
        defaultOpened={isOpen}
        icon={Icon ? <Icon /> : undefined}
        key={currentKey}
        label={item.title}
        variant="sidebarDark"
      >
        {item.children?.map((subItem) => (
          <ChessNavLink
            item={subItem}
            key={`subItem-${currentKey}-${subItem.key}`}
            openMenuKeys={openMenuKeys}
            parentKey={currentKey}
            parentLink={currentLink}
            selectedMenuKeys={selectedMenuKeys}
          />
        ))}
      </NavLink>
    </ChessNavLinkWrapper>
  );
}

export const SideNavbar: FC = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const me = api.user.studentAuth.useStudentMe();
  const { openMenuKeys, selectedMenuKeys } = useContext(PageMetaContext);

  const links = useMemo(
    () =>
      sidebars({ isFirstClass: me.data?.groupName?.startsWith('1') }).map((item) => (
        <ChessNavLink
          item={item}
          key={item.key}
          openMenuKeys={openMenuKeys}
          selectedMenuKeys={selectedMenuKeys}
        />
      )),
    [language, openMenuKeys, selectedMenuKeys],
  );

  return (
    <>
      <Navbar
        className="lg:block hidden !p-0 !z-0 bg-primary-900 text-white"
        p="xs"
        width={{ base: 50, sm: 270 }}
      >
        <Navbar.Section className="h-20">
          <Link
            className="flex items-center justify-center gap-3.5 p-6 mx-auto overflow-hidden"
            to="/"
          >
            <IllustrationLogoIcon className="w-8 h-8" />
            <span className="font-bold text-xl text-white whitespace-nowrap truncate tracking-wide">
              Chess <span className="text-primary">Class</span>
            </span>
          </Link>
        </Navbar.Section>

        <Navbar.Section grow mt={30}>
          <Stack spacing="xs">{links}</Stack>
        </Navbar.Section>
      </Navbar>
    </>
  );
};
