import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PageMeta } from '~/context/PageMetaContext';

import ResetEmailForm from '../organisms/ResetEmailForm';

const ResetEmailPage: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageMeta title={t('auth.recoverPassword')} />
      <ResetEmailForm />
    </>
  );
};

export default ResetEmailPage;
