import { IPageActions } from '@chess-class/sandbox';

import { ILessonPageActionTypesEnum } from '~/modules/lessons/consts/pageActions/ILessonPageActions';

enum IHomeworkPageActionsEnum {
  'view',
  'execute',
}

export type IHomeworkPageActions = {
  action: keyof typeof IHomeworkPageActionsEnum;
  actionId: string;
  actionType: keyof typeof ILessonPageActionTypesEnum;
};

export const homeworkPageActions: IPageActions<IHomeworkPageActions> = {
  action: {
    allowed: (value) => Object.keys(IHomeworkPageActionsEnum).includes(value),
    default: '',
  },
  actionId: { default: '' },
  actionType: { default: '' },
};
