import { useEffect, useState } from 'react';
import i18n, { t } from 'i18next';
import { Button, clsx, Image, Modal, Skeleton, Switch } from '@mantine/core';

import { api, IEnumClassQuizStudentExerciseStatusKeys } from '@chess-class/sandbox';
import { TestAnswersBox } from '@chess-class/sandbox/components';

import HomeworkStatusBox from '~mHomework/components/atoms/HomeworkStatusBox';

export default function TestDetailsModal({
  answerChoice,
  exerciseId,
  isBackButtonDisabled,
  isNextButtonDisabled,
  isOpen,
  isView = false,
  onBackButtonClick,
  onCancel,
  onNextButtonClick,
  status,
}: {
  answerChoice?: number;
  exerciseId: string;
  isBackButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
  isOpen: boolean;
  isView?: boolean;
  onBackButtonClick: () => void;
  onCancel: () => void;
  onNextButtonClick: () => void;
  status?: IEnumClassQuizStudentExerciseStatusKeys;
}) {
  const [showAnswer, setShowAnswer] = useState(isView);

  const exerciseQuery = api.content.exercises.useExercise({ id: exerciseId });
  const exercise = exerciseQuery.data?.data;

  useEffect(() => {
    setShowAnswer(isView);
  }, [exercise, answerChoice]);

  return (
    <Modal
      classNames={{
        body: 'p-6 md:p-8 bg-gray-116',
        header: 'p-4 md:p-6 gap-4 md:gap-8',
        root: 'max-w-full',
      }}
      onClose={onCancel}
      opened={isOpen}
      // scrollAreaComponent={ScrollArea.Autosize}
      size="95%"
      title={
        <div className="flex flex-row gap-3 items-center justify-between w-full">
          <div className="flex items-center">
            <img
              alt="Test"
              className="w-12 h-12 mr-3 md:mr-6 object-contain"
              src="/images/illustrations/test-img.png"
            />{' '}
            <span className="text-lg md:text-2xl font-bold">
              {`${t('test')} #${exercise?.numId}`}
            </span>
          </div>
          {!isView && (
            <Switch
              checked={showAnswer}
              classNames={{
                body: 'flex flex-col md:flex-row items-center text-center gap-2',
                label: 'p-0 md:pr-2',
              }}
              label={t('studiosPage.showAnswer')}
              labelPosition="left"
              onChange={() => {
                setShowAnswer(!showAnswer);
              }}
            />
          )}
          {status && <HomeworkStatusBox status={status} />}
        </div>
      }
    >
      {exerciseQuery.isFetching ? (
        <div className="flex flex-col gap-10 items-center">
          <Skeleton className="w-1/2 h-40" radius="md" />
          <Skeleton className="w-32 h-5" />
          <div className="flex flex-col gap-4 w-full items-center">
            <Skeleton className="w-full h-16" />
            <Skeleton className="w-full h-16" />
            <Skeleton className="w-full h-16" />
            <Skeleton className="w-full h-16" />
          </div>
        </div>
      ) : (
        <div className={clsx('flex flex-col gap-6')}>
          {showAnswer && !!exercise?.test?.answerImageUrl ? (
            <Image
              alt=""
              className="object-contain mt-5"
              maw={300}
              mx="auto"
              radius="md"
              src={`${exercise?.test?.answerImageUrl}`}
            />
          ) : (
            <Image
              alt=""
              className="object-contain"
              maw={300}
              mx="auto"
              radius="md"
              src={`${exercise?.test?.imageUrl}`}
            />
          )}
          <p className="my-12 text-center text-lg font-bold">
            {exercise?.test?.translations?.[i18n.language]?.question}
          </p>
          {exercise && (
            <TestAnswersBox
              exercise={exercise}
              isView={isView}
              onAnswer={(correct) => {
                setShowAnswer(correct);
              }}
              showAnswer={showAnswer}
            />
          )}
        </div>
      )}
      <div className="flex flex-row items-end justify-center mt-8">
        <Button
          className="w-44 mr-2"
          disabled={isBackButtonDisabled}
          onClick={onBackButtonClick}
          variant="outline"
        >
          {t('back')}
        </Button>
        <Button className="w-44" disabled={isNextButtonDisabled} onClick={onNextButtonClick}>
          {t('next')}
        </Button>
      </div>
    </Modal>
  );
}
