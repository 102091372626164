import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { getGender, IStudent } from '@chess-class/sandbox';

import { StudentContext } from '~/context/StudentContext';

type ProfileInfoProps = {
  student: IStudent;
};

const ProfileInfo: FC<ProfileInfoProps> = ({ student }) => {
  const { t } = useTranslation();
  const { school } = useContext(StudentContext);

  return (
    <div className="grid mt-6 md:grid-cols-2 gap-0">
      <div>
        <p className="font-bold text-[#5F6574] mb-2 leading-4 text-base">
          {t('profilePage.email')}
        </p>
        <p className="font-normal text-[#5F6574] mb-4 md:mb-6">{student.email}</p>
      </div>
      <div>
        <p className="font-bold text-[#5F6574] mb-2 leading-4 text-base">
          {school?.educationFormat == 'GENERAL' ? t('class') : t('group')}
        </p>
        <p className="font-normal text-[#5F6574] mb-4 md:mb-6">{student.groupName}</p>
      </div>
      <div>
        <p className="font-bold text-base text-[#5F6574] mb-2 leading-4">
          {t('profilePage.birthDate')}
        </p>
        <p className="font-normal text-[#5F6574] mb-4 md:mb-6">
          {moment(student.birthDate).format('DD.MM.YYYY')}
        </p>
      </div>
      <div>
        <p className="font-bold text-base text-[#5F6574] mb-2 leading-4">
          {t('profilePage.phone')}
        </p>
        <p className="font-normal text-[#5F6574] mb-4 md:mb-6">
          {student.phone ? student.phone : '-'}
        </p>
      </div>
      <div>
        <p className="font-bold text-base text-[#5F6574] mb-2 leading-4">
          {t('profilePage.gender')}
        </p>
        <p className="font-normal text-[#5F6574] mb-4 md:mb-6">
          {getGender().find((gender) => gender.value == student?.gender)?.label}
        </p>
      </div>
      <div>
        <p className="font-bold text-base text-[#5F6574] mb-2 leading-4"> {t('profilePage.iin')}</p>
        <p className="font-normal text-[#5F6574] mb-4 md:mb-6">{student.iin ? student.iin : '-'}</p>
      </div>
    </div>
  );
};

export default ProfileInfo;
