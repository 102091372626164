import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { ActionIcon, Button } from '@mantine/core';

import { IClassQuizSessionDetailed, IHomeworkSession } from '@chess-class/sandbox';
import {
  ArrowBackIcon,
  IllustrationGemIcon,
  IllustrationShieldIcon,
} from '@chess-class/sandbox/icons';

import AttemptsTooltip from '~mHomework/components/atoms/AttemptsTooltip';
import { QuizBox } from '~mHomework/components/organisms/QuizBox';

export default function QuizStudioStartBox({
  onStart,
  quiz,
}: {
  onStart: () => void;
  quiz: IHomeworkSession | IClassQuizSessionDetailed;
}) {
  return (
    <div className="grid md:gap-6 lg:w-2/3 self-center mx-4 md:mx-20 my-4 lg:my-16 ">
      <div className="flex md:gap-1.5 justify-between md:justify-end md:place-self-end items-baseline">
        <Link className="md:hidden block" to="../..">
          <ActionIcon className="rounded-[10px] border-[#E4E6ED] mb-4" size={40} variant="default">
            <ArrowBackIcon className="w-4 h-4 text-gray-400" />
          </ActionIcon>
        </Link>

        <div className="flex">
          <div className="flex gap-1.5 place-self-end">
            <IllustrationGemIcon className="text-cyan-101 w-5 h-5" />
            <span className="text-gray-400 font-semibold relative">
              <span className="text-primary-800">{t('attempts')}</span> {quiz.totalAttempts}
              <div
                className="transition-all duration-500 ease-in-out absolute w-[130%] aspect-square left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 z-[501] shadow-[0_0_0_max(150vh,150vw)_rgba(50,56,78,0.70)] rounded-full opacity-0"
                id="attempts-highlight"
              />
            </span>
            <AttemptsTooltip />
          </div>
        </div>
      </div>

      <div className="bg-white flex flex-col py-8 px-4 md:py-10 md:px-8 gap-6 rounded-lg border ">
        <QuizBox quiz={quiz} showChapters />

        <div className="bg-yellow-101 border-yellow-102 border rounded-lg p-6 flex gap-6">
          <IllustrationShieldIcon className="h-12 w-9" />
          <div className="flex flex-col gap-1">
            <span className="font-bold text-primary-900 text-base">{t('attention')}</span>
            <span className="text-gray-700">{t('assignmentsPage.checkpointMessage')}</span>
          </div>
        </div>
      </div>
      <div>
        <Button
          classNames={{ root: 'px-20 w-full md:w-auto mt-4' }}
          onClick={onStart}
          variant="primary"
        >
          {t('next')}
        </Button>
      </div>
    </div>
  );
}
