import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';
import { ActionIcon, Burger, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { IEnumLocalization } from '@chess-class/sandbox';
import { ArrowBackIcon } from '@chess-class/sandbox/icons';

import { DrawerMenu } from '~/components/molecules/DrawerMenu';
import { LanguageDropdown } from '~/components/molecules/LanguageDropdown';
import ProfileDropdown from '~/components/molecules/ProfileDropdown';
import AuthRoute from '~/components/molecules/routes/AuthRoute';
import { SideNavbar } from '~/components/organisms/SideNavbar';
import { usePageMetaContext } from '~/context/PageMetaContext';
import { StudentContext } from '~/context/StudentContext';

const AdminLayout: FC = () => {
  const { goBack } = usePageMetaContext();
  const { t } = useTranslation();
  const { school } = useContext(StudentContext);

  const [drawerOpened, { close: closeDrawer, toggle: toggleDrawer }] = useDisclosure(false);

  const filteredLanguages = useMemo(() => {
    if (school?.educationFormat == 'GENERAL') {
      return Object.keys(IEnumLocalization).filter((value) => value != 'en');
    } else return Object.keys(IEnumLocalization);
  }, [school]);

  return (
    <AuthRoute auth>
      <div className="h-full w-full flex overflow-hidden">
        <SideNavbar />
        <div className="flex flex-col min-w-0 min-h-0 w-full h-full overflow-hidden">
          <div className="flex items-center justify-between bg-white px-6 h-20 border-b grow-0 shrink-0">
            {!!goBack && (
              <div className="hidden items-center lg:flex h-full">
                <Link to={goBack?.url || '..'}>
                  <Button
                    className="py-3 border-gray-101 font-normal"
                    leftIcon={<ArrowBackIcon className="w-4 h-4 text-gray-400" />}
                    variant="outline"
                  >
                    {t((goBack?.i18Title as 'back') || 'back')}
                  </Button>
                </Link>
              </div>
            )}

            <div className="hidden lg:flex items-center ml-auto gap-8">
              <LanguageDropdown languages={filteredLanguages} />
              <ProfileDropdown />
            </div>

            <div className="flex lg:hidden justify-between w-full items-center">
              <div className="p-2 bg-[#F7F7FB] rounded-lg w-10 h-10 !leading-none">
                <img
                  alt="Chess Class logo"
                  className="objectx-contain !leading-none w-6 h-6"
                  src="/logo.png"
                />
              </div>
              <Burger
                className="block lg:hidden p-0"
                onClick={toggleDrawer}
                opened={drawerOpened}
                size={24}
              />
            </div>
          </div>

          <div className="w-full h-full overflow-x-hidden overflow-y-auto lg:py-10 lg:px-8 p-4 bg-[#FAFAFA]">
            {!!goBack && (
              <Link className="lg:hidden block" to={goBack?.url || '..'}>
                <ActionIcon
                  className="rounded-[10px] border-[#E4E6ED] mb-4"
                  size={40}
                  variant="default"
                >
                  <ArrowBackIcon className="w-4 h-4 text-gray-400" />
                </ActionIcon>
              </Link>
            )}

            <DrawerMenu
              closeDrawer={closeDrawer}
              drawerOpened={drawerOpened}
              languages={filteredLanguages}
            />
            <Outlet />
          </div>
        </div>
      </div>
    </AuthRoute>
  );
};

export default AdminLayout;
