import { useContext } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import dayjs from 'dayjs';
import i18n, { t } from 'i18next';
import { Button, Tooltip } from '@mantine/core';

import {
  IClassQuizSession,
  IClassQuizSessionDetailed,
  IHomeworkSession,
} from '@chess-class/sandbox';
import { GradeBox } from '@chess-class/sandbox/components';

import { StudentContext } from '~/context/StudentContext';

import AttemptsTooltip from '~mHomework/components/atoms/AttemptsTooltip';

export default function QuizBoxBottomRow({
  isHomework,
  onExecute,
  quiz,
  showActions,
  showGrade,
}: {
  isHomework?: boolean;
  onExecute?: (id: string) => void;
  quiz: IClassQuizSessionDetailed | IHomeworkSession | IClassQuizSession;
  showActions?: boolean;
  showGrade?: boolean;
}) {
  const quizStatus = dayjs(quiz.startAt).isAfter(dayjs())
    ? t('assignmentsPage.taskNotStarted')
    : dayjs(quiz.finishAt).isBefore(dayjs())
      ? t('assignmentsPage.taskHasFinished')
      : '';

  const { school } = useContext(StudentContext);

  return (
    <div className="flex justify-between md:items-end flex-col lg:flex-row lg:items-center gap-4 flex-wrap">
      {showGrade && quiz.status === 'FINISHED' && (
        <div className="flex items-center">
          <span className="font-semibold text-gray-400">
            {school?.educationFormat == 'GENERAL' ? t('grade') : t('result')}:{' '}
          </span>
          <GradeBox
            className="ml-1"
            educationFormat={school?.educationFormat}
            grade={
              school?.educationFormat == 'GENERAL'
                ? (quiz as IHomeworkSession).evaluation ||
                  (quiz.totalSolved /
                    (quiz?.studentExercises?.length || quiz.totalAttempts / 2 || 1)) *
                    100
                : Math.round((quiz.totalSolved / (quiz?.studentExercises?.length || 1)) * 100)
            }
            gradeType={quiz.gradeType}
          />
        </div>
      )}

      <div
        className={clsx(
          'flex font-semibold items-center gap-1',
          showGrade && quiz.status === 'FINISHED' ? 'ml-auto' : '',
        )}
      >
        {quiz.status == 'FINISHED' ? (
          <span className="text-gray-400 my-4 lg:my-0">
            {t('homeworksPage.usedAttemptsCount')}:{' '}
            <span className="text-yellow-103">{quiz.attempts || 0} </span>
            {i18n.language == 'kz' ? '/ ' : ' из '} {quiz.totalAttempts || 0}
          </span>
        ) : (
          <span className="text-gray-400">
            {t('homeworksPage.attempts')}:{' '}
            <span className="text-yellow-103">
              {(quiz.totalAttempts || 0) - (quiz.attempts || 0)}
            </span>
          </span>
        )}
        <AttemptsTooltip />
      </div>

      {showActions && quiz.status === 'FINISHED' && (
        <Link
          className="w-full lg:w-auto ml-auto"
          onClick={() =>
            ReactGA.event({
              action: 'completed_homework',
              category: 'completed_homework',
              label: 'completed_homework',
            })
          }
          to={`/${isHomework ? 'homework' : 'classQuizzes'}/${quiz.id}`}
        >
          <Button className="w-full lg:w-52" variant="primary">
            {t('more')}
          </Button>
        </Link>
      )}

      {showActions && quiz.status !== 'FINISHED' && (
        <div className="flex md:gap-6 gap-4 w-full md:w-auto flex-col md:flex-row">
          {isHomework ? (
            <>
              <Link
                className="w-full md:w-52"
                onClick={() =>
                  ReactGA.event({
                    action: 'view_lesson',
                    category: 'view_lesson',
                    label: 'view_lesson',
                  })
                }
                to={`/lessons/${(quiz as IHomeworkSession).chapter.id}`}
              >
                <Button className="md:w-52 w-full" variant="secondary">
                  {t('homeworksPage.watchChapter')}
                </Button>
              </Link>
              <Tooltip
                events={{ focus: true, hover: true, touch: true }}
                label={quizStatus}
                offset={10}
                opened={quizStatus ? undefined : false}
                radius="sm"
              >
                <div>
                  <Button
                    className="md:w-52 w-full"
                    disabled={!!quizStatus}
                    onClick={() => onExecute?.(quiz.id)}
                    variant="primary"
                  >
                    {t('homeworksPage.execute')}
                  </Button>
                </div>
              </Tooltip>
            </>
          ) : (
            <Tooltip
              events={{ focus: true, hover: true, touch: true }}
              label={quizStatus}
              offset={10}
              opened={quizStatus ? undefined : false}
              position="bottom"
            >
              <Link
                className="w-full md:w-52"
                to={quizStatus ? '#' : `/classQuizzes/complete/${quiz.id}`}
              >
                <Button className="md:w-52 w-full" disabled={!!quizStatus} variant="primary">
                  {t('homeworksPage.execute')}
                </Button>
              </Link>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
}
