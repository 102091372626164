import { createFormContext } from '@mantine/form';

import {
  IStudentProfilePasswordUpdateRequest,
  IStudentProfileUpdateRequest,
} from '@chess-class/sandbox';

type UserProfileFormValues = IStudentProfileUpdateRequest & IStudentProfilePasswordUpdateRequest;

export const [UserFormProvider, useUserFormContext, useUserForm] =
  createFormContext<UserProfileFormValues>();
