import { IFilters, IPageAbleRequest } from '@chess-class/sandbox';
import { paginationBaseFilters } from '@chess-class/sandbox/consts';

export const lessonsFilters: IFilters = {
  ...paginationBaseFilters,
  date: {},
  search: {},
};

export type LessonsFilters = Omit<IPageAbleRequest, 'sort'> & { date?: string; search?: string };
