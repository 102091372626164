import { useTranslation } from 'react-i18next';
import { Table, Text } from '@mantine/core';

import { IExercise } from '@chess-class/sandbox';

import { ILessonPageActions } from '~mLessons/consts/pageActions/ILessonPageActions';

export default function ExerciseCardTable({
  exercises,
  setActioned,
}: {
  exercises: IExercise[];
  setActioned: (actioned: Partial<ILessonPageActions>) => void;
}) {
  const { t } = useTranslation();

  const ths = (
    <tr>
      <th>{t('homeworksPage.tasks')}</th>
    </tr>
  );

  const rows = exercises
    .sort((a, b) => (a.numId ?? 0) - (b.numId ?? 0))
    .map((exercise) => (
      <tr
        className="cursor-pointer"
        key={exercise.id}
        onClick={() =>
          setActioned({
            action: 'view',
            actionId: exercise.id,
            actionType: exercise.type == 'TEST' ? 'test' : 'exercise',
          })
        }
      >
        <td className="flex flex-row items-center bg-transparent px-4 py-2 md:px-6 md:py-5">
          <img
            alt="Test"
            className="w-12 h-12 mr-6 object-contain"
            src={`/images/illustrations/${
              exercise.type == 'TEST' ? 'test-img' : 'problem-img'
            }.png`}
          />
          <Text className="text-sm md:text-base text-[#11122C] font-bold">
            {exercise.type == 'TEST' ? t('test') : t('exercise')} #{exercise.numId}
          </Text>
        </td>
      </tr>
    ));

  return (
    <Table className="bg-white card-table rounded-xl p-6 mb-5" highlightOnHover>
      <thead className="hidden md:block">{ths}</thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}
