import { t } from 'i18next';
import { clsx, Tooltip } from '@mantine/core';

import { QuestionMarkFilledIcon } from '@chess-class/sandbox/icons';

export default function AttemptsTooltip({ size }: { size?: 'lg' }) {
  return (
    <Tooltip
      classNames={{
        arrow: 'border border-solid border-l-primary border-t-primary',
        tooltip:
          'p-6 bg-white border rounded-lg border-primary whitespace-break-spaces text-gray-600 font-medium',
      }}
      events={{ focus: true, hover: true, touch: true }}
      label={t('modals.exerciseAttemptsTooltipDescription')}
      maw={450}
      position="bottom"
      withArrow
    >
      <div className="flex items-center">
        <QuestionMarkFilledIcon
          className={clsx('text-yellow-103', size == 'lg' ? 'h-6 w-6' : 'h-4 w-4')}
        />
      </div>
    </Tooltip>
  );
}
