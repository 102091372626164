import { IExercise } from '@chess-class/sandbox';
import { ExerciseCustomProblem } from '@chess-class/sandbox/components';

export default function StudioExerciseProblemCustom({
  disabled,
  exercise,
  onAnswer,
}: {
  disabled?: boolean;
  exercise: IExercise;
  onAnswer: (passed: boolean) => void;
}) {
  return (
    <ExerciseCustomProblem
      customProblem={exercise.customProblem!}
      disabled={disabled}
      onResult={(type) => {
        onAnswer(type == 'win');
      }}
      sidebar={{ show: true }}
    />
  );
}
