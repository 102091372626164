import { IFilters } from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

export const homeworkFilters: IFilters = {
  ...paginationBaseFilters,
  [modelKeys.education.homeworkSession.status]: {
    queries: [
      {
        default: 'ISSUED',
      },
    ],
  },
  sort: { queries: [{ default: '-createdDate' }] },
};
